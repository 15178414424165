import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Header,
  Input,
  Label,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';
import {
  FEET_TO_METER,
  METER_TO_FEET,
  SITE_MAX_HEIGHT_M,
} from 'src/app.constants';
import { encodeLatLong, isValidNumber } from 'src/utils/useful_functions';
import messages from '../../messages';
import { createSites, getNearbySites, PAGE_SIZE } from './CSVSiteValidation';
import { DESCRIPTION_MAX_LENGTH, NAME_MAX_LENGTH } from 'src/app.constants';
import { RootStateOrAny } from 'src/store';

function KMLSiteData(props) {
  const {
    data,
    handlePrevStep,
    closeFunc,
    groupKind = 'network_sites',
    intl,
  } = props;
  const { formatMessage } = intl;
  const [isLoading, setIsLoading] = useState(false);
  const { projectId, prefs } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const sites = useSelector((state) => {
    if (groupKind === 'network_sites')
      return state['mainFrame']['networkSites'];
    else if (groupKind === 'subscriber_sites')
      return state['mainFrame']['subscriberSites'];
  });
  const { heightUnits } = prefs;
  const dispatch = useDispatch();
  const [height, setHeight] = useState(
    heightUnits == 'm' ? 10.0 : (10 * METER_TO_FEET).toFixed(1)
  );
  const headers = ['Name', 'Latitude', 'Longitude', 'Description'];

  const nearbySites = [];
  const truncatedSites = [];
  const processedData = data.map((row) => {
    const { description, lat, lng, name } = row;
    const truncateValue = [];

    if (name !== null) {
      if (name.length > NAME_MAX_LENGTH) {
        row['name'] = name.substring(0, NAME_MAX_LENGTH);
        truncateValue.push('name');
      }
    }

    if (description !== null) {
      if (description.length > DESCRIPTION_MAX_LENGTH) {
        row['description'] = description.substring(0, DESCRIPTION_MAX_LENGTH);
        truncateValue.push('description');
      }
    }
    row['truncatedColumns'] = truncateValue;

    if (truncateValue.length) {
      truncatedSites.push(row);
    }

    const nearbySiteExists = getNearbySites(sites?.features, [lng, lat]);
    row['nearbySite'] = nearbySiteExists;
    if (nearbySiteExists) {
      nearbySites.push(row);
    }
    return row;
  });

  const sliceData = processedData.slice(0, PAGE_SIZE);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Header as="h5" style={{ display: 'inline', paddingRight: '5px' }}>
            <FormattedMessage
              id="siteProperties.maxHeight"
              defaultMessage="Maximum Height"
            />
          </Header>
          <Input
            placeholder="Maximum height"
            type="number"
            step="0.01"
            onChange={(e, { value }) => {
              const maxValue =
                heightUnits == 'm'
                  ? SITE_MAX_HEIGHT_M
                  : SITE_MAX_HEIGHT_M * METER_TO_FEET;
              if (isValidNumber(value, 0, maxValue, 1)) {
                setHeight(value);
              }
            }}
            value={height}
          />
          <Label basic className="no-border" style={{ fontSize: '10px' }}>
            {heightUnits}
          </Label>
        </div>
        {nearbySites.length > 0 && (
          <>
            <div className="custom-warning">
              {formatMessage(messages.nearbySiteMsg)}
            </div>
            <Label className="custom-warning">
              {formatMessage(messages.warningSites)}: {nearbySites.length}
            </Label>
          </>
        )}
        {truncatedSites.length > 0 && (
          <>
            <div className="custom-warning">
              {formatMessage(messages.warningTruncate)}
            </div>
            <Label className="custom-warning">
              {formatMessage(messages.warningSites)}: {truncatedSites.length}
            </Label>
          </>
        )}
        <Label>
          {formatMessage(messages.totalSites)}: {processedData.length}
        </Label>
      </div>
      <div className="site-import-table">
        <Table celled compact>
          <Table.Header>
            <Table.Row>
              {headers.map((value: string) => {
                return <Table.HeaderCell key={value}>{value}</Table.HeaderCell>;
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(nearbySites.length > 0
              ? nearbySites.slice(0, PAGE_SIZE)
              : sliceData
            ).map((row, j: number) => {
              return (
                <Table.Row
                  key={row['lat']}
                  className={row.nearbySite ? 'custom-warning' : ''}
                >
                  {Object.keys(row).map((key) => {
                    if (key.includes('lat'))
                      return (
                        <Table.Cell key={key}>
                          {encodeLatLong(row[key], prefs.latLngFormat)}
                        </Table.Cell>
                      );
                    else if (key.includes('lng'))
                      return (
                        <Table.Cell key={key}>
                          {encodeLatLong(row[key], prefs.latLngFormat, false)}
                        </Table.Cell>
                      );
                    else if (key.includes('description'))
                      return (
                        <Table.Cell key={key} style={{ maxWidth: '40vw' }}>
                          <div
                            className={
                              row['truncatedColumns'].includes('description')
                                ? 'custom-warning'
                                : 'kml-site-description'
                            }
                          >
                            {row[key]}
                          </div>
                        </Table.Cell>
                      );
                    else if (key.includes('name'))
                      return (
                        <Table.Cell
                          className={
                            row['truncatedColumns'].includes('name')
                              ? 'custom-warning'
                              : 'kml-site-name'
                          }
                          key={key}
                        >
                          {row[key]}
                        </Table.Cell>
                      );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
      {processedData.length == 0 && (
        <Message attached="bottom" error>
          <FormattedMessage
            id="common.fileError"
            defaultMessage="Problem with file. Please send the file to linkplanner@cambiumnetworks.com"
          />
        </Message>
      )}
      <Segment
        basic
        textAlign="right"
        floated="right"
        style={{ padding: '0', margin: '0 0 1rem 0' }}
      >
        <Button
          floated="right"
          color="blue"
          className="ml-2"
          disabled={processedData.length == 0 || isLoading}
          onClick={async () => {
            setIsLoading(true);
            const postData = [];
            const is_network_site = groupKind === 'network_sites';
            const maxHeight =
              heightUnits === 'm' ? height : Number(height) * FEET_TO_METER;
            processedData.forEach((obj) => {
              const { name, lat, lng, description } = obj;
              postData.push({
                name,
                latitude: lat,
                longitude: lng,
                maximum_height: maxHeight,
                height_units: 'm',
                description,
                is_network_site,
              });
            });
            try {
              await createSites(
                dispatch,
                projectId,
                data,
                groupKind,
                closeFunc,
                'm',
                postData
                //formValues.heightUnits
              );
            } finally {
              setIsLoading(false);
            }
          }}
        >
          {isLoading ? 'Processing...' : formatMessage(messages.createSites)}
        </Button>
        <Button floated="right" onClick={() => handlePrevStep()}>
          {formatMessage(messages.previous)}
        </Button>{' '}
      </Segment>
    </>
  );
}

export default injectIntl(KMLSiteData);
