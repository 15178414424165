import {
  ClutterDetailsType,
  ClutterTypes,
  LidarClutterDetailsType,
  LidarClutterTypes,
} from '../../utils/types/commonTypes';
import { clone } from '../../utils/chartUtils';
import { radioHeight } from '../../utils/P526_10';
import { worstEarth } from '../../utils/P530_12';
import { ProfilePoint } from './ProfilePoint';
import { last } from '../../utils/useful_functions';
import { profileInterpolation } from './ProfileChart';
import { LIDAR_CLUTTER_DETAILS } from 'src/app.constants';

export const fresnelMultiplier = (useClutter: boolean): number => {
  if (useClutter) {
    return 0.6;
  } else {
    return 0.78;
  }
};

export type ProfileType = {
  localHeight: number;
  remoteHeight: number;
  ranges: number[];
  heights: number[];
  obstructions: number[];
  use_clutter: boolean;
  clutterTypes: string[];
  frequencyGHz: number;
  clutterDetails: ClutterDetailsType | LidarClutterDetailsType;
  is_lidar: boolean;
};

export class Profile {
  localHeight: number = 0;
  remoteHeight: number = 0;
  ranges: number[] = [];
  heights: number[] = [];
  radioHeights: number[] = [];
  worstEarthHeights: number[] = [];
  obstructions: number[] = [];
  use_clutter: boolean = false;
  is_lidar: boolean = false;
  clutter: string = '';
  points: ProfilePoint[] = [];
  clutterTypes: string[] = [];
  highPoints: ProfilePoint[] = [];
  interpolatedProfile: Profile | null = null;
  pathLength: number = 0;
  fresMultiplier = fresnelMultiplier(true);
  frequencyGHz = 5.8;
  clutterDetails: ClutterDetailsType | LidarClutterDetailsType = [];
  constructor({
    ranges = [],
    heights = [],
    clutterTypes = [],
    obstructions = [],
    use_clutter = false,
    localHeight = 0,
    remoteHeight = 0,
    frequencyGHz = 5.8,
    clutterDetails,
    is_lidar,
  }: ProfileType) {
    this.localHeight = localHeight;
    this.remoteHeight = remoteHeight;
    this.ranges = ranges;
    this.heights = heights;
    this.obstructions = obstructions;
    this.use_clutter = use_clutter;
    this.clutterTypes = clutterTypes;
    this.pathLength = last(ranges);
    this.frequencyGHz = frequencyGHz;
    this.is_lidar = is_lidar;
    if (is_lidar) {
      this.clutterDetails = LIDAR_CLUTTER_DETAILS;
    } else {
      this.clutterDetails = clutterDetails;
    }
    // Array of Profilepoint instances
    this.points = [];
    this.fresMultiplier = fresnelMultiplier(this.use_clutter);
    this.populatePoints();
  }

  populatePoints() {
    const ke = worstEarth(this.pathLength);
    this.ranges.forEach((range, i) => {
      const curvature = radioHeight(this.pathLength, range);
      const worstEarth = radioHeight(this.pathLength, range, ke);
      let clutterInfo = null;
      clutterInfo = filterClutterInfo(
        this.clutterDetails,
        this.clutterTypes[i]
      );
      const point = new ProfilePoint(
        range,
        this.heights[i],
        this.clutterTypes[i],
        this.obstructions[i],
        i,
        this.use_clutter,
        worstEarth,
        curvature,
        clutterInfo
      );
      // For start and end points we need to add antenna heights
      // to totalheights and normal heights
      if (i === 0) {
        point.antennaHeight = this.localHeight;
        //point.totalHeight += this.localHeight;
      }
      if (i === this.ranges.length - 1) {
        point.antennaHeight = this.remoteHeight;
        //point.totalHeight += this.remoteHeight;
      }
      this.points.push(point);
    });
  }

  calculateInterpolatedProfile(highPoint: boolean = false) {
    const { ranges, heights, clutter_types, obstructions } =
      profileInterpolation(
        this.ranges,
        this.heights,
        this.clutterTypes,
        this.obstructions,
        this.clutterDetails,
        highPoint
      );
    return new Profile({
      localHeight: this.localHeight,
      remoteHeight: this.remoteHeight,
      ranges: ranges,
      heights: heights,
      clutterTypes: clutter_types,
      obstructions: obstructions,
      use_clutter: this.use_clutter,
      frequencyGHz: this.frequencyGHz,
      clutterDetails: this.clutterDetails,
      is_lidar: this.is_lidar,
    });
  }

  calculateHighPoints() {
    let profile: Profile = this;
    if (this.use_clutter) {
      profile = this.calculateInterpolatedProfile(true);
    }
    // start highpoint
    const startHP: ProfilePoint = profile.points[0];
    const startHPCopy = clone(startHP);
    startHPCopy.totalHeight = startHPCopy.height + startHPCopy.antennaHeight;
    this.highPoints.push(startHPCopy);
    const hp1 = this.findHighPoint(profile.points, 'hp1');
    if (hp1) {
      const mHPIndex = hp1.index;
      const rightPoints: ProfilePoint[] = profile.points.slice(mHPIndex);
      const hp2Points: ProfilePoint[] = [];
      rightPoints.forEach((point: ProfilePoint) => {
        const copy: ProfilePoint = clone(point);
        copy.range = profile.pathLength - point.range;
        hp2Points.push(copy);
      });
      this.highPoints.push(hp1);
      const hp2 = this.findHighPoint(hp2Points.reverse(), 'hp2');
      if (hp2) {
        this.highPoints.push(profile.points[hp2.index]);
        const middlePoints = profile.points.slice(hp1.index, hp2.index + 1);
        const hp3Points: ProfilePoint[] = [];
        middlePoints.forEach((point: ProfilePoint) => {
          const copy: ProfilePoint = clone(point);
          copy.range = copy.range - hp1.range;
          hp3Points.push(copy);
        });
        const hp3 = this.findHighPoint(hp3Points, 'hp3');
        if (hp3) {
          this.highPoints.push(profile.points[hp3.index]);
        }
      }
    }
    //end highpoint
    const endHP: ProfilePoint = last(profile.points);
    const endHPCopy = clone(endHP);
    endHPCopy.totalHeight = endHPCopy.height + endHPCopy.antennaHeight;
    this.highPoints.push(endHPCopy);
    this.highPoints = this.highPoints.sort((a, b) => a.range - b.range);
    return this.highPoints;
  }

  private findHighPoint(
    points: ProfilePoint[],
    type: string
  ): ProfilePoint | null {
    const observer: ProfilePoint = points[0];
    let ratioValue = -Infinity;
    let localHeight = observer.totalHeight + observer.antennaHeight;
    const ratio = (height: number, range: number) =>
      (height - localHeight) / range;
    let highPoint: ProfilePoint | null = null;
    const target: ProfilePoint = last(points);
    const pathLength = target.range;
    let remoteHeight = target.totalHeight + target.antennaHeight;
    if (type === 'hp1') {
      localHeight = observer.height + observer.antennaHeight;
      remoteHeight = target.height + target.antennaHeight;
    } else if (type === 'hp2') {
      localHeight = observer.height + observer.antennaHeight;
    }
    let remoteRatio = ratio(remoteHeight, pathLength);
    points = points.slice(1, -1);
    points.forEach((point: ProfilePoint, i) => {
      const { range, totalHeight } = point;
      const currentRatio = ratio(totalHeight, range);
      if (currentRatio >= remoteRatio && currentRatio > ratioValue) {
        ratioValue = currentRatio;
        highPoint = point;
      }
    });
    return highPoint;
  }
}

function filterClutterInfo(
  clutterDetails: ClutterDetailsType | LidarClutterDetailsType,
  type: string
): { key: ClutterTypes | LidarClutterTypes; details: [string, number] } {
  if (!type) {
    return {
      key: 'U',
      details: ['#FFFFFF', 0],
    } as { key: ClutterTypes | LidarClutterTypes; details: [string, number] };
  } else {
    return clutterDetails.filter((obj) => obj.key === type.toUpperCase())[0];
  }
}
