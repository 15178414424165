import React, { FormEvent, useEffect, useState, useMemo, useRef } from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Button,
  Form,
  Message,
  Segment,
  Table,
  Label,
} from 'semantic-ui-react';
import { point } from '@turf/helpers';
import distance from '@turf/distance';
import additionalMessages from '../../messages';
import { decodeLatLong, getGridPageSize } from '../../utils/useful_functions';
import { postWithAuth } from '../../api';
import { fetchSites, warningMsg } from '../mainframe/mainframe.reducer';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { DESCRIPTION_MAX_LENGTH, NAME_MAX_LENGTH } from 'src/app.constants';

export type CSVSiteValidationPropsType = {
  data: string[][];
  handlePrevStep: Function;
  skipFirstRow: boolean;
  groupKind: string;
  closeFunc: Function;
} & WrappedComponentProps;
export const PAGE_SIZE = 20;
export const createSites = (
  dispatch: Dispatch<any>,
  projectId: string,
  validData: any[],
  groupKind: string,
  closeFunc: Function,
  heightUnits: string,
  postData?: any
) => {
  const is_network_site = groupKind === 'network_sites';
  let siteData = postData
    ? postData
    : validData.map(({ data }) => {
        const [
          name,
          latitude,
          longitude,
          maximum_height,
          description,
          node_type,
        ] = data;
        return {
          name,
          latitude: decodeLatLong(latitude, true)[0],
          longitude: decodeLatLong(longitude, false)[0],
          maximum_height: parseFloat(maximum_height),
          height_units: heightUnits,
          description,
          is_network_site,
          node_type: node_type,
        };
      });
  return postWithAuth(`project/${projectId}/sites`, siteData)
    .then(() => {
      dispatch(fetchSites(projectId));
      closeFunc();
    })
    .catch((err) => {
      console.error(err);
      if (err.detail) {
        toast(<Message error>{err.detail}</Message>, {
          autoClose: false,
        });
      }
      closeFunc();
    });
};

function CSVSiteValidation(props: CSVSiteValidationPropsType) {
  const { projectId } = useSelector((state: any) => state.mainFrame);
  const {
    data,
    handlePrevStep,
    closeFunc,
    skipFirstRow = true,
    groupKind = 'network_sites',
    intl,
  } = props;
  const { formatMessage } = intl;
  const dispatch = useDispatch();
  const [slicedData, setSlicedData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [validData, setValidData] = useState([]);
  const [errData, setErrData] = useState({
    error: false,
    warning: false,
    truncate: false,
    siteWithNearSites: [],
    errorSites: [],
    isEmpty: false,
  });
  const [formValues, setFormValues] = useState({
    skipFirstRow: skipFirstRow,
    heightUnits: 'm',
  });
  const [isLoading, setIsLoading] = useState(false);
  const defaultHeaders = useMemo(() => {
    let result = [
      formatMessage(additionalMessages.name),
      formatMessage(additionalMessages.latitude),
      formatMessage(additionalMessages.longitude),
      `${formatMessage(additionalMessages.maxHeight)} (${
        formValues.heightUnits
      })`,
      formatMessage(additionalMessages.description),
    ];

    if (groupKind === 'network_sites') {
      result.push(formatMessage(additionalMessages.nodeType));
    }

    return result;
  }, [formValues.heightUnits, formatMessage, groupKind]);

  const [headerRow, setHeaderRow] = useState(defaultHeaders);
  const totalSites = useRef(0);
  const sites = useSelector((state) => {
    if (groupKind === 'network_sites')
      return state['mainFrame']['networkSites'];
    else if (groupKind === 'subscriber_sites')
      return state['mainFrame']['subscriberSites'];
  });

  const prevStepHandler = () => {
    handlePrevStep();
  };
  useEffect(() => {
    const [validData, errorSites, siteWithNearSites, truncData, isEmpty] =
      parseSiteCSV(
        data,
        sites.features,
        formValues.skipFirstRow,
        groupKind === 'network_sites'
      );
    setValidData(validData);
    totalSites.current = validData.length;
    setErrData({
      warning: siteWithNearSites.length ? true : false,
      truncate: truncData.length ? true : false,
      error: errorSites.length > 0 || isEmpty,
      siteWithNearSites,
      errorSites,
      isEmpty,
    });

    const invalidData = errorSites.concat(siteWithNearSites);
    if (invalidData.length) {
      setGridData(invalidData.slice(0, PAGE_SIZE));
      totalSites.current += invalidData.length;
    } else {
      setGridData(validData.slice(0, PAGE_SIZE));
    }
  }, [data, formValues.skipFirstRow, PAGE_SIZE, sites.features]);

  useEffect(() => {
    let firstPageData = gridData.slice(0, PAGE_SIZE);
    setSlicedData(firstPageData);
  }, [gridData, PAGE_SIZE]);

  useEffect(() => {
    if (formValues.skipFirstRow) {
      // use the first row as the headers
      const headers = data[0];
      // We want to restrict the number of columns.
      // Network sites expect the node type column, but
      // this should not be there for subscriber sites.
      const lastIndex = groupKind === 'network_sites' ? 6 : 5;
      setHeaderRow(headers.slice(0, lastIndex));
    } else {
      setHeaderRow(defaultHeaders);
    }
  }, [data, defaultHeaders, formValues]);

  const handleChange = (e: FormEvent<HTMLElement>, data: any) => {
    setFormValues({ ...formValues, [data.name]: data.value || data.checked });
  };

  let sampleSitesCsvUrl;
  let errorMessageId;
  if (groupKind === 'network_sites') {
    sampleSitesCsvUrl = '/sample/Network_Sites_Sample.csv';
    errorMessageId = 'importCSV.errorMessageNetwork';
  } else {
    sampleSitesCsvUrl = '/sample/Subscriber_Sites_Sample.csv';
    errorMessageId = 'importCSV.errorMessageSubscriber';
  }

  if (process.env.NODE_ENV !== 'development') {
    sampleSitesCsvUrl = '/assets' + sampleSitesCsvUrl;
  }

  return (
    <>
      <Form>
        <Form.Group inline style={{ justifyContent: 'space-between' }}>
          <div
            className="ui form d-flex inline fields"
            style={{ marginBottom: '0em' }}
          >
            <label>{formatMessage(additionalMessages.heightUnits)}</label>
            <Form.Radio
              label={formatMessage(additionalMessages.meters)}
              name="heightUnits"
              value="m"
              checked={formValues.heightUnits === 'm'}
              onChange={(e, data) => handleChange(e, data)}
            />
            <Form.Radio
              label={formatMessage(additionalMessages.feet)}
              name="heightUnits"
              value="ft"
              checked={formValues.heightUnits === 'ft'}
              onChange={(e, data) => handleChange(e, data)}
            />
            <Form.Checkbox
              name="skipFirstRow"
              checked={formValues.skipFirstRow}
              onChange={(e, data) => handleChange(e, data)}
              label={formatMessage(additionalMessages.fileHeaderMsg)}
            />
          </div>
          <div>
            {errData.error && !errData.isEmpty && (
              <div className="custom-error">
                {formatMessage(additionalMessages.errorFieldMsg)}
              </div>
            )}
            {errData.error && errData.isEmpty && (
              <div className="custom-error">
                {formatMessage(additionalMessages.emptyOrCorruptFile)}
              </div>
            )}
            {errData.warning && (
              <div className="custom-warning">
                {formatMessage(additionalMessages.siteWarningMsg)}
              </div>
            )}
            {errData.truncate && (
              <div className="custom-warning">
                {formatMessage(additionalMessages.warningTruncate)}
              </div>
            )}
          </div>
          {errData.error && (
            <Label basic color="red">
              {formatMessage(additionalMessages.errorSites)}:{' '}
              {errData.errorSites.length}
            </Label>
          )}
          {errData.warning && (
            <Label className="custom-warning">
              {formatMessage(additionalMessages.warningSites)}:{' '}
              {errData.siteWithNearSites.length}
            </Label>
          )}
          <Label>
            {formatMessage(additionalMessages.totalSites)}: {totalSites.current}
          </Label>
        </Form.Group>
        {errData.error && (
          <div className="custom-error" style={{ marginBottom: '0.8em' }}>
            <FormattedMessage
              id={errorMessageId}
              values={{
                a: (msg: any) => (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={sampleSitesCsvUrl}
                  >
                    {msg}
                  </a>
                ),
              }}
            />
          </div>
        )}
      </Form>
      <div className="site-import-table mb-4">
        <Table celled compact>
          {headerRow && (
            <Table.Header>
              <Table.Row>
                {headerRow.map((value: string, i: number) => {
                  return (
                    <Table.HeaderCell key={value + i}>{value}</Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body>
            {slicedData.map((row, j: number) => {
              return (
                <Table.Row
                  key={row.data[0] + j}
                  className={row.nearbySite ? 'custom-warning' : ''}
                >
                  {row.data.map((value: string, index: number) => {
                    return (
                      <Table.Cell
                        key={value + index}
                        negative={row.errors.includes(index)}
                        className={
                          row.truncates?.includes(index) || false
                            ? 'custom-warning'
                            : ''
                        }
                      >
                        {value}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>

      <Segment
        basic
        textAlign="right"
        floated="right"
        style={{ padding: '0', margin: '0 0 1rem 0' }}
      >
        <Button
          floated="right"
          color="blue"
          disabled={errData.error || isLoading}
          className="ml-2"
          onClick={async () => {
            setIsLoading(true);
            try {
              await createSites(
                dispatch,
                projectId,
                // there is case where all the sites nearby sites
                validData.length == 0 ? errData.siteWithNearSites : validData,
                groupKind,
                closeFunc,
                formValues.heightUnits
              );
            } finally {
              setIsLoading(false);
            }
          }}
        >
          {isLoading
            ? 'Processing...'
            : formatMessage(additionalMessages.createSites)}
        </Button>
        <Button floated="right" onClick={() => prevStepHandler()}>
          {formatMessage(additionalMessages.previous)}
        </Button>
      </Segment>
    </>
  );
}

export default injectIntl(CSVSiteValidation);

export function getNearbySites(
  existingSites: any,
  newSiteCoords: [number, number]
) {
  let to = null;
  try {
    to = point(newSiteCoords);
  } catch (e) {
    return false;
  }
  return existingSites.some((site: any) => {
    const from = point(site.geometry.coordinates);
    // gap is in KM by default
    const gap = distance(from, to);
    return gap <= 0.001;
  });
}

export function parseSiteCSV(
  data: string[][],
  sites: any,
  skipFirstRow: boolean,
  areNetworkSites: boolean
) {
  type ParsedSiteCSVRow = {
    data: string[];
    errors: number[];
    nearbySite: boolean;
    truncates?: number[];
  };

  const validData: ParsedSiteCSVRow[] = [];
  const errorData: ParsedSiteCSVRow[] = [];
  const truncData: ParsedSiteCSVRow[] = [];
  const siteWithNearSites: ParsedSiteCSVRow[] = [];
  let isEmpty = false;

  // if data is less than 2 rows then skipping of slice one, where we need to show the error
  if (data.length > 1 && skipFirstRow) {
    data = data.slice(1);
  }

  // if data is empty setting error
  if (data.length === 0) {
    isEmpty = true;
  }

  // ignore rows with 1 or 0 cols - we assume its garbage data that cant be parsed
  data = data.filter((row) => row.length > 1);
  if (data.length === 0) {
    isEmpty = true;
  }

  data.forEach((row) => {
    // Need to consider only first five columns of file
    // for subscriber sites, but we also want the
    // node type for network sites
    const lastIndex = areNetworkSites ? 6 : 5;
    row = row.slice(0, lastIndex);
    const name = row[0];
    const lat = row[1];
    const lng = row[2];
    const maxHeight = row[3];
    const description = row[4] || '';
    const latDecoded = decodeLatLong(lat);
    const lngDecoded = decodeLatLong(lng, false);
    const errors = [];
    const truncates = [];

    if (areNetworkSites) {
      const nodeType = row[5];
      if (nodeType === undefined || nodeType === null) {
        row[5] = '';
      } else if (['', 'DN', 'POP'].indexOf(nodeType) < 0) {
        errors.push(5);
      }
    }

    if (name.length > NAME_MAX_LENGTH) {
      row[0] = name.substring(0, NAME_MAX_LENGTH);
      truncates.push(0);
    }
    if (description.length > DESCRIPTION_MAX_LENGTH) {
      row[4] = description.substring(0, DESCRIPTION_MAX_LENGTH);
      truncates.push(4);
    }
    if (!latDecoded[1]) {
      errors.push(1);
    }
    if (!lngDecoded[1]) {
      errors.push(2);
    }
    if (maxHeight && isNaN(Number(maxHeight))) {
      errors.push(3);
    }
    let nearbySiteExists = false;
    if (latDecoded[1] && lngDecoded[1])
      nearbySiteExists = getNearbySites(sites, [lngDecoded[0], latDecoded[0]]);
    if (truncates.length) {
      truncData.push({
        data: row,
        errors: errors,
        truncates,
        nearbySite: nearbySiteExists,
      });
    }
    if (errors.length) {
      errorData.push({
        data: row,
        errors: errors,
        nearbySite: nearbySiteExists,
      });
    } else if (nearbySiteExists) {
      siteWithNearSites.push({
        data: row,
        errors: errors,
        nearbySite: nearbySiteExists,
      });
    } else {
      validData.push({
        data: row,
        errors: errors,
        nearbySite: nearbySiteExists,
        truncates: truncates,
      });
    }
  });

  return [validData, errorData, siteWithNearSites, truncData, isEmpty];
}
