import {
  band,
  product,
  regulation,
  smRangeBaseConfig,
} from '../../config-utils';
import NDEquipmentPanel from '../../nd/NDEquipmentPanel';
import { PMPFormPanel } from '../../nd/utils';
import { syncFormState } from './../utils';
import { antenna, power } from './PMP450ViewConfig';

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'sm_equipment',
  component: NDEquipmentPanel,
  fields: [band, product, regulation],
};

const productPanel: PMPFormPanel = {
  kind: 'product',
  title: 'Product Configuration',
  name: 'sm_product',
  component: NDEquipmentPanel,
  fields: [
    {
      attrName: 'bandwidth',
      getter: 'radios.0.equipment.bandwidth',
    },
    {
      ...smRangeBaseConfig,
      editable: false,
    },
    {
      attrName: 'dl_ul_ratio',
      getter: 'radios[0].equipment.dl_ul_ratio',
    },
    {
      attrName: 'pmp_max_mod_mode',
      getter: 'radios[0].equipment.pmp_max_mod_mode',
      refreshesChoices: true,
    },
    {
      attrName: 'frame_period',
      getter: 'radios[0].equipment.frame_period',
    },
  ],
};

const ePMPViewConfig = {
  syncFormState,
  equipment,
  product: productPanel,
  antenna,
  power,
};
export default ePMPViewConfig;
