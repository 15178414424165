import React, { useState, useRef } from 'react';
import { flatten, throttle } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import additionalMessages from '../../messages';
import { area, line, curveNatural } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import { CustomLayer, ResponsiveLine } from '@nivo/line';
import { calcFresnelRadius } from '../../utils/P526_10';
import {
  getLabelsByPrefs,
  calcRadioHeights,
  calculateTicks,
  getMinMaxOfSeries,
  getSightLine,
  calcPrecision,
  getXPosition,
} from '../../utils/chartUtils';
import {
  BROWN,
  BLUE,
  GRAY,
  YELLOW,
  RED,
  MIN_LINK_LENGTH_M,
} from '../../app.constants';
import { Container, Dimmer, Loader, Message } from 'semantic-ui-react';
import { Profile, fresnelMultiplier } from './Profile';
import { ProfilePoint } from './ProfilePoint';
import { useSelector, useDispatch } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { ClutterDetailsType } from '../../utils/types/commonTypes';
import {
  meterToAny,
  fixedPrecision,
  last,
  findIndexAtRange,
  pyround,
  km_to_length,
} from '../../utils/useful_functions';
import { updateSelection } from './profile.reducer';
import { ReflectionLine } from 'src/model/Reflection';

const MARGIN = { top: 10, right: 62, bottom: 43, left: 60 };

/*
 * Backend schema when requesting/editing a profile
 */
export type ProfileResponse = {
  loc_lat: number;
  loc_lng: number;
  rem_lat: number;
  rem_lng: number;
  ranges: number[];
  heights: number[];
  clutter_types: string[] | null;
  obstructions: number[];
  use_clutter: boolean;
  is_lidar: boolean;
  // This isn't part of the response from the backend
  // but it is used to handle errors in the request
  errMsg: string | null;
  // Used to indicate if the UI is waiting for the response
  loading: boolean;
};

export type ProfileChartProps = {
  profileData: ProfileResponse;
  localHeight: number;
  remoteHeight: number;
  loading: boolean;
  frequencyGHz: number;
  rangeUnits: string;
  heightUnits: string;
  localSiteName: string;
  remoteSiteName: string;
  isEditing: boolean;
  reflectionLine?: ReflectionLine;
  modified?: boolean;
  setModified?: Function;
};

/*
 * Return the title for the path profile
 *
 * PathLength: float in metres
 * profileType: String, e.g. "Line-of-Sight", "Near Line-of-Sight"
 * prefs: prefs object
 */
export const getProfileTitle = (
  pathLength: number,
  profileType: string,
  prefs: any
) => {
  const displayLength = pyround(km_to_length(pathLength / 1000), 3);
  const lengthUnits = prefs.rangeUnits === 'km' ? 'kilometers' : 'miles';
  return `Profile : ${displayLength} ${lengthUnits}, ${profileType}`;
};

export const profileInterpolation = (
  ranges: number[],
  heights: number[],
  clutter_types: string[],
  obstructions: number[],
  clutterDetails: any,
  highPoint: boolean = false
) => {
  if (!ranges || ranges.length === 0) {
    // when the data is empty, return the original values
    return { ranges, heights, clutter_types, obstructions };
  }

  const clutterLookupTable = Object.fromEntries(
    clutterDetails.map((c) => [c.key, c.details[1]])
  );

  const interpRanges: number[] = [];
  const interpHeights: number[] = [];
  const interpClutterTypes: string[] = [];
  const interpObstructions: number[] = [];
  interpRanges.push(ranges[0]);
  interpHeights.push(heights[0]);
  if (highPoint) {
    interpObstructions.push(0);
    interpClutterTypes.push('U');
  } else {
    // intermediate points calculation for clutter
    interpClutterTypes.push(clutter_types[0]);
    interpObstructions.push(obstructions[0]);
  }

  const lastIndex = heights.length - 2;
  heights.forEach((item, index) => {
    if (heights[index + 1] != null) {
      const clutterHeight = clutterLookupTable[clutter_types[index]];
      const nxtClutterHeight = clutterLookupTable[clutter_types[index + 1]];
      interpHeights.push((heights[index] + heights[index + 1]) / 2);
      interpRanges.push((ranges[index] + ranges[index + 1]) / 2);
      if (highPoint && index === 0) {
        interpObstructions.push(obstructions[index + 1]);
        interpClutterTypes.push(clutter_types[index + 1]);
      } else if (highPoint && index === lastIndex) {
        interpObstructions.push(obstructions[index]);
        interpClutterTypes.push(clutter_types[index]);
      } else if (
        clutterHeight + obstructions[index] >
        nxtClutterHeight + obstructions[index + 1]
      ) {
        interpObstructions.push(obstructions[index]);
        interpClutterTypes.push(clutter_types[index]);
      } else {
        interpObstructions.push(obstructions[index + 1]);
        interpClutterTypes.push(clutter_types[index + 1]);
      }

      interpHeights.push(heights[index + 1]);
      interpRanges.push(ranges[index + 1]);
      if (highPoint && index === lastIndex) {
        interpClutterTypes.push('U');
        interpObstructions.push(0);
      } else {
        interpClutterTypes.push(clutter_types[index + 1]);
        interpObstructions.push(obstructions[index + 1]);
      }
    }
  });

  return {
    ranges: interpRanges,
    heights: interpHeights,
    clutter_types: interpClutterTypes,
    obstructions: interpObstructions,
  };
};

/*
 * Return the profile type as a Line-of-Sight string
 */
export const getProfileType = (
  localHeight: number,
  remoteHeight: number,
  frequencyGHz: number,
  profileData: ProfileResponse,
  clutterDetails: any
): string => {
  if (!profileData) {
    return '';
  }

  let { ranges, heights, clutter_types, obstructions, use_clutter } =
    profileData;
  if (use_clutter) {
    const interpolatedProfile = profileInterpolation(
      ranges,
      heights,
      clutter_types,
      obstructions,
      clutterDetails
    );
    ranges = interpolatedProfile.ranges;
    heights = interpolatedProfile.heights;
    clutter_types = interpolatedProfile.clutter_types;
    obstructions = interpolatedProfile.obstructions;
  }

  const clutterLookupTable = Object.fromEntries(
    clutterDetails.map((c) => [c.key, c.details[1]])
  );
  const pathLength = last(ranges);
  const fzMultiplier = fresnelMultiplier(use_clutter);
  // Local and remote antenna heights above sea level
  const localASL = localHeight + heights[0];
  const remoteASL = remoteHeight + last(heights);
  let nearLOS = false;
  for (let idx = 0; idx <= ranges.length - 1; idx++) {
    const currentRange = ranges[idx];
    let totalHeight = heights[idx] + obstructions[idx];
    if (use_clutter) {
      const clutterHeight = clutterLookupTable[clutter_types[idx]];

      totalHeight += clutterHeight;
    }
    const fresnelRadius =
      fzMultiplier * calcFresnelRadius(frequencyGHz, currentRange, pathLength);
    const sight = getSightLine({
      xpos: currentRange,
      lAntennaHeight: localASL,
      rAntennaHeight: remoteASL,
      pathLength: pathLength,
    });
    if (sight - totalHeight < 0) {
      // if any point is non-LOS, abort early
      return 'Non Line-of-Sight';
    } else if (sight - fresnelRadius - totalHeight < 0) {
      // the terrain obstructs the Fresnel Radius
      nearLOS = true;
      // continue processing for near-LOS since another point could be non-LOS
    }
  }

  if (nearLOS) {
    return 'Near Line-of-Sight';
  }
  // If we made it this far then the profile must be line-of-sight
  return 'Line-of-Sight';
};

const setChartX = (dispatch, ranges, startIndex, xpos, reset = false) => {
  const index = findIndexAtRange(xpos, ranges);
  if (reset) {
    dispatch(
      updateSelection({
        startIndex: index,
        endIndex: index,
        target: 'chart',
      })
    );
  } else {
    dispatch(
      updateSelection({
        startIndex: startIndex,
        endIndex: index,
        target: 'chart',
      })
    );
  }
};

const delayedSetChartX = throttle(
  (dispatch, ranges, startIndex, clickedRange) => {
    setChartX(dispatch, ranges, startIndex, clickedRange);
  },
  200
);

const getCurrentRange = (event, ranges) => {
  const { target } = event;
  const width = target.getAttribute('width');
  const xPosition = getXPosition(event);
  const scale = scaleLinear()
    .domain([0, width])
    .range([ranges[0], last(ranges)]);
  return scale(xPosition);
};

export type ChartPoint = {
  x: number;
  y: number;
};

export type AreaPoint = {
  x: number;
  height: number; // resembles y low values
  y: number; // resembles y high values
};

function ProfileChart({
  profileData,
  localHeight = 0,
  remoteHeight = 0,
  loading,
  frequencyGHz = 5.8,
  rangeUnits = 'km',
  heightUnits = 'm',
  localSiteName,
  remoteSiteName,
  isEditing = false,
  reflectionLine,
}: ProfileChartProps) {
  if (!profileData) {
    // nothing to draw
    return <Loader active />;
  }

  const {
    ranges,
    heights,
    clutter_types,
    obstructions,
    use_clutter,
    errMsg,
    is_lidar,
  } = profileData;

  const { formatMessage } = useIntl();
  const clutterDetails = useSelector(
    (state: RootStateOrAny) => state.mainFrame.clutterDetails
  );
  const sidebarResizing = useSelector(
    (state) => state.mainFrame.sidebarResizing
  );
  const { startIndex, endIndex } = useSelector(
    (state: RootStateOrAny) => state.profile
  );

  const isMouseDown = useRef(false);

  const dispatch = useDispatch();
  const isValidData = () => {
    let validData = true;
    if (ranges.length !== heights.length) {
      validData = false;
    } else if (use_clutter && ranges.length !== clutter_types.length) {
      validData = false;
    } else if (
      obstructions.length > 0 &&
      obstructions.length !== ranges.length
    ) {
      validData = false;
    }
    return validData;
  };

  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  } else if (errMsg) {
    return (
      <Message negative size="mini">
        <p>{errMsg}</p>
      </Message>
    );
  } else if (!isValidData()) {
    return (
      <Message negative size="mini">
        <p>{formatMessage(additionalMessages.invalidData)}</p>
      </Message>
    );
  } else {
    const pathLength = last(ranges);
    if (pathLength < MIN_LINK_LENGTH_M) {
      return (
        <Container
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <Message negative size="large">
            <FormattedMessage
              id="profile.tooShort"
              defaultMessage="Path is too short"
            />
          </Message>
        </Container>
      );
    }
    const labels = getLabelsByPrefs(
      { rangeUnits: rangeUnits, heightUnits: heightUnits },
      formatMessage
    );
    const gridLineCoordsArr: { id: string; color: string; data: any[] }[] = [];
    const profile = new Profile({
      heights,
      ranges,
      clutterTypes: clutter_types,
      obstructions,
      use_clutter,
      localHeight,
      remoteHeight,
      frequencyGHz,
      clutterDetails,
      is_lidar,
    });
    profile.calculateHighPoints();
    const xAxisPrecision = calcPrecision(profile.pathLength, rangeUnits);
    if (use_clutter) {
      profile.interpolatedProfile = profile.calculateInterpolatedProfile();
    }
    const [fresnelData, sightLineData] = getFresnelAndSightLineData(profile);
    const clutterData = getClutterData(profile, clutterDetails);
    const [obstructionData, worstEarthData] = getObsAndWorstEarthData(profile);
    const heightsData = getTerrainData(profile);

    const obstructionSeries = {
      data: obstructionData,
      color: YELLOW,
      label: formatMessage(additionalMessages.obstruction),
    };
    const worstEarthSeries = {
      data: worstEarthData,
      color: GRAY,
      label: formatMessage(additionalMessages.worstEarth),
    };
    // Custom clutter layer
    const clutterLayer = ({ xScale, yScale }: any) => {
      const areaGenerator = area()
        .x((bar) => {
          return xScale(bar['x']);
        })
        .y0((bar) => yScale(bar['height']))
        .y1((bar, i) => yScale(bar['y']));

      return clutterData.map((clutter, i) => (
        <path
          id={'clutter' + i}
          key={'clutter' + i}
          d={areaGenerator(clutter.data)!}
          fill={clutter.color}
        />
      ));
    };

    // Y axis line calculation
    const flatClutterData = flatten(clutterData.map((obj) => obj.data));
    let [yMin, yMax]: number[] = getMinMaxOfSeries([
      ...fresnelData,
      ...heightsData,
      ...obstructionData,
      ...flatClutterData,
      ...worstEarthData,
    ]);
    const xTicks = calculateTicks([ranges[0], last(ranges)]);
    if (last(xTicks) !== pathLength) {
      xTicks.push(pathLength);
    }
    const yTicks = calculateTicks([yMin, yMax]);
    const yOffsets = calcRadioHeights(ranges);
    let floatValueYTicks = false;
    yTicks.forEach((yTick, i) => {
      if (!Number.isInteger(meterToAny(yTick, heightUnits, 1))) {
        floatValueYTicks = true;
      }
      const gridLineCoords: any[] = [];
      xTicks.forEach((xTick, j) => {
        gridLineCoords.push({ x: xTick, y: yTick + yOffsets[j] });
      });
      gridLineCoordsArr.push({
        id: `Grid Line ${i}`,
        color: '#E1E1E1',
        data: gridLineCoords,
      });
    });
    const yAxisTickFormatter = (value) => {
      if (floatValueYTicks) {
        return meterToAny(value, heightUnits, 1).toFixed(1);
      } else {
        return meterToAny(value, heightUnits, 1);
      }
    };
    // not plotting the highest y axis line as it going out of chart area
    // Need to rethink
    gridLineCoordsArr.pop();

    // Custom y axis line layer
    const gridLineLayer: CustomLayer = ({ xScale, yScale }: any) => {
      const lineGenerator = line()
        .x((bar) => xScale(bar['x']))
        .y((bar) => yScale(bar['y']))
        .curve(curveNatural);
      return gridLineCoordsArr.map((gridLine, index) => (
        <path
          key={index}
          d={lineGenerator(gridLine.data)!}
          stroke="#EAEAEA"
          fill="none"
          strokeWidth={1.5}
        />
      ));
    };

    // Obstruction layer
    const obstructionLayer: CustomLayer = ({ xScale, yScale }: any) => {
      const areaGenerator = area<AreaPoint>()
        .x((bar) => {
          return xScale(bar['x']);
        })
        .y0((bar) => yScale(bar['height']))
        .y1((bar, i) => {
          return yScale(bar['y']);
        });

      return (
        <path
          id="obstructions"
          d={areaGenerator(obstructionSeries.data)!}
          fill={obstructionSeries.color}
        />
      );
    };

    // fresnel layer
    const fresnelLayer: CustomLayer = ({ xScale, yScale }: any) => {
      const lineGenerator = line<ChartPoint>()
        .x((bar) => xScale(bar['x']))
        .y((bar) => yScale(bar['y']))
        .curve(curveNatural);
      return (
        <path
          d={lineGenerator(fresnelData)!}
          stroke={BLUE}
          fill="rgba(0,0,255,0.3)"
          strokeWidth={2}
        />
      );
    };

    // sight line layer
    const sightLineLayer: CustomLayer = ({ xScale, yScale }: any) => {
      const lineGenerator = line<ChartPoint>()
        .x((bar) => xScale(bar['x']))
        .y((bar) => yScale(bar['y']))
        .curve(curveNatural);
      return (
        <path
          id="sightline"
          d={lineGenerator(sightLineData)!}
          stroke={GRAY}
          fill="none"
          strokeWidth={2}
        />
      );
    };

    // worst earth layer
    const worstEarthLayer: CustomLayer = ({ xScale, yScale }: any) => {
      const lineGenerator = line<ChartPoint>()
        .x((bar) => xScale(bar['x']))
        .y((bar) => yScale(bar['y']));
      return (
        <path
          id="worstearth"
          d={lineGenerator(worstEarthSeries.data)!}
          stroke={worstEarthSeries.color}
          fill="none"
          strokeWidth={2}
        />
      );
    };

    //selection layer
    let selectionLayer: CustomLayer = () => [];
    if (isEditing && startIndex !== null && endIndex !== null) {
      let startIdx = Math.min(startIndex, endIndex);
      let endIdx = Math.max(startIndex, endIndex);
      const start_x =
        startIdx === 0
          ? ranges[startIdx]
          : // use the mid point
            (ranges[startIdx] + ranges[startIdx - 1]) / 2.0;
      const end_x =
        endIdx === ranges.length - 1
          ? ranges[endIdx]
          : (ranges[endIdx] + ranges[endIdx + 1]) / 2.0;
      const selectionLayerData = [
        { x: start_x, y: yMin },
        { x: start_x, y: yMax },
        { x: end_x, y: yMax },
        { x: end_x, y: yMin },
      ];
      selectionLayer = ({ xScale, yScale }: any) => {
        const lineGenerator = line<ChartPoint>()
          .x((bar) => xScale(bar.x))
          .y((bar) => yScale(bar.y));
        return (
          <path
            d={lineGenerator(selectionLayerData)!}
            fill={GRAY}
            opacity={0.5}
          />
        );
      };
    }

    function getReflectionCustomLayers(reflectionLine: ReflectionLine) {
      if (reflectionLine === null || reflectionLine === undefined) {
        return [];
      }

      const { greyReflection, blueReflection } = reflectionLine;
      if (greyReflection === undefined || blueReflection === undefined) {
        return [];
      }
      const blueReflectionLength = blueReflection.length;
      const sightLineDataLength = sightLineData.length;

      //reflection layer -- grey line
      const reflectionGreyLineLayer: CustomLayer = ({
        xScale,
        yScale,
      }: any) => {
        const lineGenerator = line<ChartPoint>()
          .x((bar) => xScale(bar['x']))
          .y((bar) => yScale(bar['y']));
        return (
          <path
            id="reflectionGreyLineLayer"
            d={lineGenerator(greyReflection)!}
            stroke={GRAY}
            fill="none"
            strokeWidth={2}
          />
        );
      };

      //reflection layer -- blue line
      const reflectionBlueLineLayer: CustomLayer = ({
        xScale,
        yScale,
      }: any) => {
        const lineGenerator = line<ChartPoint>()
          .x((bar) => xScale(bar['x']))
          .y((bar) => yScale(bar['y']));
        return (
          <path
            id="reflectionBlueLineLayer"
            d={lineGenerator(blueReflection)!}
            stroke={BLUE}
            fill="none"
            strokeWidth={4}
          />
        );
      };

      // reflection layer -- local antenna to blue line left
      const reflectionLocalToBlueLineLeftLayer: CustomLayer = ({
        xScale,
        yScale,
      }: any) => {
        const lineGenerator = line<ChartPoint>()
          .x((bar) => xScale(bar['x']))
          .y((bar) => yScale(bar['y']));
        return (
          <path
            id="reflectionLocalToBlueLineLeftLayer"
            // if blueReflection array is empty, then passing sightLineData, so that it will be on same point
            d={lineGenerator([
              sightLineData[0],
              blueReflectionLength ? blueReflection[0] : sightLineData[0],
            ])}
            stroke={BLUE}
            fill="none"
            strokeWidth={2}
          />
        );
      };

      // reflection layer -- local antenna to blue line right
      const reflectionLocalToBlueLineRightLayer: CustomLayer = ({
        xScale,
        yScale,
      }: any) => {
        const lineGenerator = line<ChartPoint>()
          .x((bar) => xScale(bar['x']))
          .y((bar) => yScale(bar['y']));
        return (
          <path
            id="reflectionLocalToBlueLineRightLayer"
            d={
              lineGenerator([
                sightLineData[0],
                blueReflectionLength
                  ? blueReflection[blueReflectionLength - 1]
                  : sightLineData[0],
              ])!
            }
            stroke={BLUE}
            fill="none"
            strokeWidth={2}
          />
        );
      };

      // reflection layer -- remote antenna to blue line left
      const reflectionRemoteToBlueLineLeftLayer: CustomLayer = ({
        xScale,
        yScale,
      }: any) => {
        const lineGenerator = line<ChartPoint>()
          .x((bar) => xScale(bar['x']))
          .y((bar) => yScale(bar['y']));
        return (
          <path
            id="reflectionRemoteToBlueLineLeftLayer"
            d={
              lineGenerator([
                sightLineData[sightLineDataLength - 1],
                blueReflectionLength
                  ? blueReflection[0]
                  : sightLineData[sightLineDataLength - 1],
              ])!
            }
            stroke={BLUE}
            fill="none"
            strokeWidth={2}
          />
        );
      };

      // reflection layer -- remote antenna to blue line right
      const reflectionRemoteToBlueLineRightLayer: CustomLayer = ({
        xScale,
        yScale,
      }: any) => {
        const lineGenerator = line<ChartPoint>()
          .x((bar) => xScale(bar['x']))
          .y((bar) => yScale(bar['y']));
        return (
          <path
            id="reflectionRemoteToBlueLineRightLayer"
            d={
              lineGenerator([
                sightLineData[sightLineDataLength - 1],
                blueReflectionLength
                  ? blueReflection[blueReflectionLength - 1]
                  : sightLineData[sightLineDataLength - 1],
              ])!
            }
            stroke={BLUE}
            fill="none"
            strokeWidth={2}
          />
        );
      };

      return [
        reflectionGreyLineLayer,
        reflectionBlueLineLayer,
        reflectionLocalToBlueLineLeftLayer,
        reflectionLocalToBlueLineRightLayer,
        reflectionRemoteToBlueLineLeftLayer,
        reflectionRemoteToBlueLineRightLayer,
      ];
    }

    const reflectionCustomLayers = getReflectionCustomLayers(reflectionLine);

    let customLayers: CustomLayer[] = [
      worstEarthLayer,
      gridLineLayer,
      obstructionLayer,
      clutterLayer,
      sightLineLayer,
      fresnelLayer,
      selectionLayer,
    ];

    // non los sight line layer
    let noLosLineLayer: CustomLayer;
    if (profile.highPoints.length > 2) {
      const highPointsData = {
        data: [] as ChartPoint[],
        color: RED,
      };
      profile.highPoints.forEach((hp: ProfilePoint) => {
        highPointsData.data.push({ x: hp.range, y: hp.totalHeight });
      });
      noLosLineLayer = ({ xScale, yScale }: any) => {
        const lineGenerator = line<ChartPoint>()
          .x((bar) => xScale(bar.x))
          .y((bar) => yScale(bar.y));
        return (
          <path
            d={lineGenerator(highPointsData.data)!}
            stroke={highPointsData.color}
            fill="none"
            strokeWidth={2}
          />
        );
      };
      customLayers.splice(3, 0, noLosLineLayer);
    }

    const chartData = [
      {
        id: formatMessage(additionalMessages.groundHeight),
        color: BROWN,
        data: heightsData,
      },
    ];

    //For tooltip data
    const getObstructionAndClutter = (range: any): any[] => {
      const obsData = obstructionData;
      const obsPoints = obsData.filter((point: any) => point.x === range);

      const clutterPoints = clutterData
        //.flat()
        .filter((point: any) => point.x === range);
      return [obsPoints, clutterPoints];
    };

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ left: '65px', position: 'relative', top: '12px' }}>
            {localSiteName}
          </div>
          <div style={{ right: '65px', position: 'relative', top: '12px' }}>
            {remoteSiteName}
          </div>
        </div>
        {sidebarResizing ? null : (
          <ResponsiveLine
            enableGridY={false}
            enableArea={true}
            // Add 'slices' layer for visibility of tooltip
            layers={[
              'grid',
              'axes',
              //'slices',
              ...customLayers,
              ...reflectionCustomLayers,
              'lines',
              'areas',
              'mesh',
            ]}
            colors={{ datum: 'color' }}
            areaBaselineValue={yMin}
            data={chartData}
            margin={{ ...MARGIN }}
            xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
            yScale={{
              type: 'linear',
              min: yMin,
              max: yMax,
              stacked: false,
            }}
            axisTop={null}
            onClick={
              isEditing
                ? (a, event) => {
                    if (isMouseDown.current) {
                      // this click event is for mouseup
                      isMouseDown.current = false;
                    } else {
                      // this is a mouse down event
                      const clickedRange = getCurrentRange(event, ranges);
                      setChartX(
                        dispatch,
                        ranges,
                        startIndex,
                        clickedRange,
                        true
                      );
                    }
                  }
                : null
            }
            onMouseMove={
              isEditing
                ? (point, event) => {
                    const { buttons } = event;
                    if (buttons === 1) {
                      const clickedRange = getCurrentRange(event, ranges);
                      if (isMouseDown.current) {
                        // user is dragging
                        delayedSetChartX(
                          dispatch,
                          ranges,
                          startIndex,
                          clickedRange
                        );
                      } else {
                        // register this as a click event and start dragging
                        isMouseDown.current = true;
                        setChartX(
                          dispatch,
                          ranges,
                          startIndex,
                          clickedRange,
                          true
                        );
                      }
                    }
                  }
                : null
            }
            enablePoints={false}
            axisBottom={{
              format: function (value: any) {
                return fixedPrecision(
                  meterToAny(value, rangeUnits, xAxisPrecision),
                  xAxisPrecision
                );
              },
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: formatMessage(additionalMessages.rangeLabel, {
                unit: labels['lengthLbl'],
              }),
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            axisLeft={{
              format: function (value: any) {
                return yAxisTickFormatter(value);
              },
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: `${formatMessage(additionalMessages.heightLabel, {
                unit: labels['heightLbl'],
              })}`, // - ${localPlace}`,
              legendOffset: -55,
              legendPosition: 'middle',
            }}
            axisRight={{
              format: function (value: any) {
                return yAxisTickFormatter(value);
              },
              tickRotation: 0,
              legend: `${formatMessage(additionalMessages.heightLabel, {
                unit: labels['heightLbl'],
              })}`, // - ${remotePlace}`,
              legendOffset: 55,
              legendPosition: 'middle',
            }}
            pointSize={3}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={1}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            sliceTooltip={(input) => {
              const points = input.slice.points;
              const xpos = Number(points[0].data['x']);
              const groundHeight = Number(points[0].data['y']);
              const fresnelRadius =
                profile.fresMultiplier *
                calcFresnelRadius(profile.frequencyGHz, xpos, pathLength);
              const lAntennaHeight = localHeight + profile.heights[0] || 0; //+ groundAtAP
              const rAntennaHeight = remoteHeight + last(profile.heights) || 0; //+ groundAtSM
              const sightY = getSightLine({
                xpos,
                lAntennaHeight,
                rAntennaHeight,
                pathLength,
              });
              const fresLow = sightY - fresnelRadius;
              const distanceToFres = fresLow - groundHeight;
              let toolTipData: any = [];
              let [obsPoints, clutterPoints] = getObstructionAndClutter(
                points[0].data['x']
              );
              const obsHeight = obsPoints.length ? obsPoints[0].obstruction : 0;
              toolTipData.push({
                color: '#ffffff',
                label: formatMessage(additionalMessages.distanceToFresnel),
                value: `${meterToAny(distanceToFres)} ${heightUnits}`,
              });
              if (obsHeight !== 0) {
                toolTipData.push({
                  color: YELLOW,
                  label: formatMessage(additionalMessages.obstruction),
                  value: `${meterToAny(obsHeight)} ${heightUnits}`,
                });
              }

              clutterPoints = clutterPoints.filter(
                (point: any) => point.clutter !== 0
              );
              if (clutterPoints.length) {
                // sorting because when we have multiple clutter points(transition) at same x
                // we need to display highest clutter value
                clutterPoints.sort((a: any, b: any) => b.clutter - a.clutter);
                toolTipData.push({
                  color: clutterPoints[0]['color'],
                  label: `Clutter (${clutterPoints[0]['label']})`,
                  value: `${meterToAny(
                    clutterPoints[0]['clutter']
                  )} ${heightUnits}`,
                });
              }

              toolTipData = toolTipData.concat([
                {
                  color: BROWN,
                  label: formatMessage(additionalMessages.groundHeight),
                  value: `${meterToAny(
                    Number(groundHeight),
                    heightUnits
                  )} ${heightUnits}`,
                },
                {
                  color: '#ffffff',
                  label: formatMessage(additionalMessages.range),
                  value: `${meterToAny(
                    Number(points[0].data.x),
                    rangeUnits,
                    3
                  )} ${rangeUnits}`,
                },
              ]);

              return <Tooltip data={toolTipData}></Tooltip>;
            }}
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateY: 80,
                itemsSpacing: 0,
                itemWidth: 73,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={false}
            tooltip={() => <></>} //disabled default tooltip
          />
        )}
      </>
    );
  }
}

export function getFresnelAndSightLineData(
  profile: Profile
): Array<ChartPoint[]> {
  // Fresnel calculation
  const numPoints = 40;
  const fresUpPoints: ChartPoint[] = [];
  const fresLowPoints: ChartPoint[] = [];
  let fresnelData: ChartPoint[] = [];
  const sightLine: ChartPoint[] = [];
  const { pathLength, localHeight, remoteHeight } = profile;
  const lAntennaHeight = localHeight + profile.heights[0] || 0; //+ groundAtAP
  const rAntennaHeight = remoteHeight + last(profile.heights) || 0; //+ groundAtSM
  for (let r = 0; r <= numPoints; r++) {
    const xpos =
      (pathLength * (1 - Math.cos((Math.PI * r) / numPoints + 0.00001))) / 2;
    const sightY = getSightLine({
      xpos,
      lAntennaHeight,
      rAntennaHeight,
      pathLength,
    });
    const fresnelRadius =
      profile.fresMultiplier *
      calcFresnelRadius(profile.frequencyGHz, xpos, pathLength);
    const lowerFresnel = sightY - fresnelRadius;
    const upperFresnel = sightY + fresnelRadius;
    fresLowPoints.push({ x: xpos, y: lowerFresnel });
    fresUpPoints.push({ x: xpos, y: upperFresnel });
    sightLine.push({ x: xpos, y: sightY });
  }
  fresnelData = fresLowPoints.concat(fresUpPoints.reverse());
  return [fresnelData, sightLine];
}

export function getObsAndWorstEarthData(
  profile: Profile
): [AreaPoint[], ChartPoint[]] {
  if (profile.use_clutter) {
    profile = profile.interpolatedProfile!;
  }
  const { points } = profile;
  const xVals: number[] = [];
  const yLowVals: number[] = [];
  const yHighVals: number[] = [];
  const weXVals: number[] = [];
  const weYVals: number[] = [];
  const obstructionData: AreaPoint[] = [];
  const worstEarthData: ChartPoint[] = [];
  points.forEach(
    (startPoint: ProfilePoint, i, contextArray: ProfilePoint[]) => {
      if (profile.use_clutter) {
        if (i < contextArray.length - 1) {
          const endPoint: ProfilePoint = points[i + 1];
          let realPoint: ProfilePoint;
          const startX = startPoint.range;
          const endX = endPoint.range;
          if (i % 2 === 0) {
            realPoint = startPoint;
          } else {
            realPoint = endPoint;
          }
          const startY: number = startPoint.radioHeight;
          const endY: number = endPoint.radioHeight;
          const startYWe: number = startPoint.height + startPoint.worstEarth;
          const endYWe: number = endPoint.height + endPoint.worstEarth;
          const clutterHeight: number = realPoint.clutterHeight;
          const obstruction: number = realPoint.obstruction;
          if (obstruction) {
            xVals.push(startX, startX, endX, endX);
            yLowVals.push(
              startY + clutterHeight,
              startY + clutterHeight,
              endY + clutterHeight,
              endY + clutterHeight
            );
            yHighVals.push(
              startY + clutterHeight,
              startY + clutterHeight + obstruction,
              endY + clutterHeight + obstruction,
              endY + clutterHeight
            );
          }
          weXVals.push(startX, endX);
          weYVals.push(
            startYWe + clutterHeight + obstruction,
            endYWe + clutterHeight + obstruction
          );
        }
      } else {
        xVals.push(startPoint.range);
        yLowVals.push(startPoint.radioHeight);
        yHighVals.push(startPoint.radioHeight + startPoint.obstruction);
        weXVals.push(startPoint.range);
        weYVals.push(
          startPoint.height + startPoint.obstruction + startPoint.worstEarth
        );
      }
    }
  );
  xVals.forEach((xVal, i) => {
    obstructionData.push({
      x: xVal,
      y: yHighVals[i],
      height: yLowVals[i],
    });
  });
  weXVals.forEach((weXVal, i) => {
    worstEarthData.push({
      x: weXVal,
      y: weYVals[i],
    });
  });

  return [obstructionData, worstEarthData];
}

export function getClutterData(
  profile: Profile,
  clutterDetails: ClutterDetailsType
) {
  const realPoints = profile.points;
  if (profile.use_clutter) {
    profile = profile.interpolatedProfile!;
  }
  const cSeriesArr: any[] = [];
  let prevInterpolated: ProfilePoint;
  let xVals: number[] = [];
  let yLowVals: number[] = [];
  let yHighVals: number[] = [];
  let currentColor: string = '#ffffff00';
  let color: string;
  const intrpPoints = profile.points.filter((point, i) => i % 2 === 1);
  if (profile.use_clutter) {
    realPoints.forEach((real: ProfilePoint, i) => {
      let interpolated: ProfilePoint = intrpPoints[i];
      let startX = 0;
      let endX = 0;
      let startY = 0;
      let endY = 0;
      let middleX = 0;
      let middleY = 0;
      let cltStartY = 0;
      let cltEndY = 0;
      const series = {
        data: [] as { x: number; height: number; y: number }[],
        color: currentColor,
      };
      let clutterInfo = real.clutterInfo;
      const cltHeight = clutterInfo.details[1];
      if (!prevInterpolated) {
        //First point case
        startX = real.range;
        startY = real.radioHeight;
        currentColor = clutterInfo.details[0];
      } else {
        startX = prevInterpolated.range;
        startY = prevInterpolated.radioHeight;
      }
      if (!interpolated) {
        //last point case
        endX = real.range;
        endY = real.radioHeight;
      } else {
        endX = interpolated.range;
        endY = interpolated.radioHeight;
      }
      middleX = real.range;
      middleY = real.radioHeight + cltHeight;
      cltStartY = startY + cltHeight;
      cltEndY = endY + cltHeight;

      color = clutterInfo.details[0];
      if (currentColor !== color) {
        xVals.forEach((xVal, i) => {
          series.data.push({ x: xVal, height: yLowVals[i], y: yHighVals[i] });
        });
        cSeriesArr.push(series);
        xVals = [startX, startX, middleX, endX, endX];
        yLowVals = [startY, startY, real.radioHeight, endY, endY];
        yHighVals = [startY, cltStartY, middleY, cltEndY, endY];
        currentColor = color;
      } else {
        xVals.push(startX, startX, middleX, endX, endX);
        yLowVals.push(startY, startY, real.radioHeight, endY, endY);
        yHighVals.push(startY, cltStartY, middleY, cltEndY, endY);
      }
      prevInterpolated = interpolated;
    });
    const lastSeries = {
      data: [] as { x: number; height: number; y: number }[],
      color: currentColor,
    };
    xVals.forEach((xVal, i) => {
      lastSeries.data.push({ x: xVal, height: yLowVals[i], y: yHighVals[i] });
    });
    cSeriesArr.push(lastSeries);
  }
  return cSeriesArr;
}

export function getTerrainData(profile: Profile): ChartPoint[] {
  const terrainData: ChartPoint[] = [];
  if (profile.use_clutter) {
    profile = profile.interpolatedProfile!;
  }
  profile.points.forEach((point: ProfilePoint, i) => {
    terrainData.push({ x: point.range, y: point.radioHeight });
  });
  return terrainData;
}

export default ProfileChart;
