import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Button, Message } from 'semantic-ui-react';
import { Formik, Form as FormFormik } from 'formik';
import { injectIntl } from 'react-intl';
import SemanticField from '../../components/controls/SemanticField';
import { convertObjectKeys, toSnake, toCamel } from '../../utils/change-case';
import additionalMessages from '../../messages';
import { updateAdminToolsApi, getWithAuth } from '../../api';
import { trimObject } from '../../utils/useful_functions';
import { useDispatch } from 'react-redux';
import { getAllowedFeatures } from '../mainframe/mainframe.reducer';
import { toast } from 'react-toastify';
import { getUserLimits } from '../../pages/mainframe/mainframe.reducer';

const isValid = (value, min, max) => {
  const numberRegex = `^\\d*(\\.\\d*})?$`;
  const floatValue = parseFloat(value);
  let valid = false;
  const regex = new RegExp(numberRegex, 'g');
  if (regex.test(value) && floatValue >= min && floatValue <= max) {
    valid = true;
  }
  return valid;
};

function UserInfoSettings({ intl }) {
  const { formatMessage } = intl;
  const [userInfo, setUserInfo] = useState(null);
  const [isUserInfoValModified, setIsUserInfoValModified] = useState(false);
  const [isEmailModified, setIsEmailModified] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const dispatch = useDispatch<any>();

  const initialValues = {
    email: userInfo?.email || '',
    otherUsers: userInfo?.otherUsers || [],
    enabled: userInfo?.enabled || false,
    projects: userInfo?.limits?.projects || '',
    networkSites: userInfo?.limits?.networkSites || '',
    subscriberSites: userInfo?.limits?.subscriberSites || '',
    pmpNetworkDevices: userInfo?.limits?.pmpNetworkDevices || '',
    pmpLinks: userInfo?.limits?.pmpLinks || '',
    ptpLinks: userInfo?.limits?.ptpLinks || '',
    features: {
      defaultLidarProjects: userInfo?.features?.defaultLidarProjects || false,
      defaultLidarLength: userInfo?.features?.defaultLidarLength || '',
    },
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(
        formatMessage(additionalMessages.validFieldError, {
          fieldName: 'Email',
        })
      )
      .required(
        formatMessage(additionalMessages.requiredFieldError, {
          fieldName: 'Email',
        })
      ),
    projects: Yup.number()
      .positive()
      .integer()
      .test((num) => isValid(num, 0, Number.MAX_SAFE_INTEGER)),
    networkSites: Yup.number()
      .positive()
      .integer()
      .test((num) => isValid(num, 0, Number.MAX_SAFE_INTEGER)),
    subscriberSites: Yup.number()
      .positive()
      .integer()
      .test((num) => isValid(num, 0, Number.MAX_SAFE_INTEGER)),
    pmpNetworkDevices: Yup.number()
      .positive()
      .integer()
      .test((num) => isValid(num, 0, Number.MAX_SAFE_INTEGER)),
    pmpLinks: Yup.number()
      .positive()
      .integer()
      .test((num) => isValid(num, 0, Number.MAX_SAFE_INTEGER)),
    ptpLinks: Yup.number()
      .positive()
      .integer()
      .test((num) => isValid(num, 0, Number.MAX_SAFE_INTEGER)),
    features: Yup.object({
      defaultLidarLength: Yup.number()
        .positive()
        .integer()
        .test((num) => isValid(num, 0, Number.MAX_SAFE_INTEGER)),
    }),
  });

  const doChange = (e) => {
    if (e.target.name === 'enabled') {
      setUserInfo((info) => ({ ...info, [e.target.name]: e.target.checked }));
      setIsUserInfoValModified(true);
    } else if (e.target.name === 'email') {
      setUserInfo((info) => ({ ...info, [e.target.name]: e.target.value }));
      setIsEmailModified(true);
    } else {
      setUserInfo((info) => ({
        ...info,
        limits: {
          ...info.limits,
          [e.target.name]: parseInt(e.target.value, 10),
        },
      }));
      setIsUserInfoValModified(true);
    }
  };

  const onSubmit = (values, restoreDefault = false) => {
    values = {
      email: userInfo.email,
      enabled: restoreDefault ? true : userInfo.enabled,
      limits: restoreDefault ? {} : convertObjectKeys(userInfo.limits, toSnake),
      features: restoreDefault
        ? {}
        : convertObjectKeys(userInfo.features, toSnake),
    };
    trimObject(values);
    const snakeVals: any = convertObjectKeys(values, toSnake);
    updateAdminToolsApi(snakeVals)
      .then((res) => {
        restoreDefault && onFindHandler({});
        dispatch(getAllowedFeatures());
        toast(
          <Message success>{'Updated user information settings'}</Message>,
          {
            autoClose: false,
          }
        );
        dispatch(getUserLimits());
      })
      .catch((err) => {
        setErrMsg(err.message);
        console.error('Error while updating admintools', err);
      });
  };

  const onFindHandler = ({ email = null }) => {
    const findEmail = email || initialValues.email.trim();
    getWithAuth(`admin/user?email=${findEmail}`)
      .then((res) => {
        const camelVals = convertObjectKeys(res.limits, toCamel);
        setUserInfo({
          email: res.email,
          otherUsers: res.other_users,
          enabled: res.enabled,
          limits: { ...camelVals },
          features: convertObjectKeys(res.features, toCamel),
        });
        setErrMsg('');
        setIsEmailModified(false);
        setIsUserInfoValModified(false);
      })
      .catch((err) => {
        setErrMsg('Unknown user');
        setUserInfo({
          email: userInfo.email,
          enabled: false,
          limits: {
            projects: '',
            networkSites: '',
            subscriberSites: '',
            pmpLinks: '',
            ptpLinks: '',
            pmpNetworkDevices: '',
          },
          features: {
            defaultLidarProjects: false,
            defaultLidarLength: '',
          },
        });
        console.error('Unknown user', err);
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={isUserInfoValModified && validationSchema}
        onSubmit={(values) => onSubmit(values)}
        validateOnMount
      >
        {(formik) => {
          return (
            <FormFormik className="ui form">
              <Form.Group widths="equal">
                <SemanticField
                  label={formatMessage(
                    additionalMessages.adminUserInfoSettingsEmail
                  )}
                  component={Form.Input}
                  type="email"
                  checkPermissions={false}
                  id="email"
                  name="email"
                  onChange={doChange}
                ></SemanticField>
                <Button
                  type="button"
                  onClick={() => onFindHandler({})}
                  style={{ margin: '15px 4px 0' }}
                >
                  {formatMessage(additionalMessages.adminUserInfoSettingsFind)}
                </Button>
              </Form.Group>
              {initialValues.otherUsers.length > 0 && (
                <>
                  <label>Similar Emails:</label>
                  <div className="admin-users">
                    {initialValues.otherUsers.map((u, idx) => (
                      <div
                        className="user-details"
                        key={`${idx}-${u}`}
                        onClick={() => {
                          setUserInfo((info) => ({ ...info, ['email']: u }));
                          setIsEmailModified(true);
                          onFindHandler({ email: u });
                        }}
                      >
                        {u}
                      </div>
                    ))}
                  </div>
                </>
              )}
              <Form.Group widths="equal">
                <SemanticField
                  label={formatMessage(
                    additionalMessages.adminUserInfoSettingsEnabled
                  )}
                  component={Form.Checkbox}
                  id="enabled"
                  name="enabled"
                  defaultChecked={userInfo?.enabled}
                  checkPermissions={false}
                  disabled={!userInfo || isEmailModified || errMsg}
                  onChange={doChange}
                >
                  <input />
                </SemanticField>
                <SemanticField
                  label={formatMessage(
                    additionalMessages.adminUserInfoSettingsProjects
                  )}
                  component={Form.Input}
                  type="number"
                  id="projects"
                  name="projects"
                  checkPermissions={false}
                  disabled={!userInfo || isEmailModified || errMsg}
                  onChange={doChange}
                >
                  <input />
                </SemanticField>
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField
                  label={formatMessage(
                    additionalMessages.adminUserInfoSettingsNetworkSites
                  )}
                  component={Form.Input}
                  type="number"
                  id="networkSites"
                  name="networkSites"
                  checkPermissions={false}
                  disabled={!userInfo || isEmailModified || errMsg}
                  onChange={doChange}
                >
                  <input />
                </SemanticField>
                <SemanticField
                  label={formatMessage(
                    additionalMessages.adminUserInfoSettingsSubscriberSites
                  )}
                  component={Form.Input}
                  type="number"
                  id="subscriberSites"
                  name="subscriberSites"
                  checkPermissions={false}
                  disabled={!userInfo || isEmailModified || errMsg}
                  onChange={doChange}
                >
                  <input />
                </SemanticField>
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField
                  label={formatMessage(
                    additionalMessages.adminUserInfoSettingsPMPNetworkDevices
                  )}
                  component={Form.Input}
                  type="number"
                  id="pmpNetworkDevices"
                  name="pmpNetworkDevices"
                  pattern="[1-9]"
                  checkPermissions={false}
                  disabled={!userInfo || isEmailModified || errMsg}
                  onChange={doChange}
                >
                  <input />
                </SemanticField>
                <SemanticField
                  label={formatMessage(
                    additionalMessages.adminUserInfoSettingsPMPLinks
                  )}
                  component={Form.Input}
                  type="number"
                  id="pmpLinks"
                  name="pmpLinks"
                  checkPermissions={false}
                  disabled={!userInfo || isEmailModified || errMsg}
                  onChange={doChange}
                >
                  <input />
                </SemanticField>
              </Form.Group>
              <Form.Group>
                <SemanticField
                  label={formatMessage(additionalMessages.ptpLinks)}
                  width={8}
                  component={Form.Input}
                  type="number"
                  id="ptpLinks"
                  name="ptpLinks"
                  pattern="[1-9]"
                  checkPermissions={false}
                  disabled={!userInfo || isEmailModified || errMsg}
                  onChange={doChange}
                >
                  <input />
                </SemanticField>
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField
                  label={formatMessage(
                    additionalMessages.adminUserInfoSettingsLidarProjects
                  )}
                  component={Form.Checkbox}
                  id="lidarProjects"
                  name="features.defaultLidarProjects"
                  defaultChecked={userInfo?.features?.defaultLidarProjects}
                  checkPermissions={false}
                  disabled={!userInfo || isEmailModified || errMsg}
                  onChange={(e) => {
                    setUserInfo((info) => ({
                      ...info,
                      features: {
                        ...info.features,
                        defaultLidarProjects: e.target.checked,
                      },
                    }));
                    setIsUserInfoValModified(true);
                  }}
                >
                  <input />
                </SemanticField>
                <SemanticField
                  label={formatMessage(
                    additionalMessages.adminUserInfoSettingsLidarLength
                  )}
                  component={Form.Input}
                  type="number"
                  id="lidarLength"
                  name="features.defaultLidarLength"
                  checkPermissions={false}
                  disabled={!userInfo || isEmailModified || errMsg}
                  onChange={(e) => {
                    setUserInfo((info) => ({
                      ...info,
                      features: {
                        ...info.features,
                        defaultLidarLength: parseInt(e.target.value),
                      },
                    }));
                    setIsUserInfoValModified(true);
                  }}
                >
                  <input />
                </SemanticField>
              </Form.Group>
              <Form.Group
                style={{ justifyContent: 'end', marginBottom: '0px' }}
              >
                <Button
                  type="button"
                  disabled={!userInfo || isEmailModified}
                  color="blue"
                  onClick={(values) => onSubmit(values, true)}
                >
                  {formatMessage(
                    additionalMessages.adminUserInfoSettingsRestoreDefault
                  )}
                </Button>
                <Button
                  type="submit"
                  disabled={
                    !userInfo ||
                    !isUserInfoValModified ||
                    isEmailModified ||
                    !formik.isValid
                  }
                  color="blue"
                >
                  {formatMessage(
                    additionalMessages.adminUserInfoSettingsUpdate
                  )}
                </Button>
              </Form.Group>
            </FormFormik>
          );
        }}
      </Formik>
      {errMsg !== '' && (
        <Message negative attached>
          <Message.Header>
            {formatMessage(additionalMessages.adminToolsError)}
          </Message.Header>
          <p>{errMsg}</p>
        </Message>
      )}
    </>
  );
}

export default injectIntl(UserInfoSettings);
