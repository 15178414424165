import {
  band,
  gasGroup,
  GasGroupsSpectra,
  product,
  regulation,
  smRangeBaseConfig,
} from '../../config-utils';
import NDEquipmentPanel from '../../nd/NDEquipmentPanel';
import NDPowerPanel from '../../nd/NDPowerPanel';
import { PMPFormPanel } from '../../nd/utils';
import { syncFormState } from './../utils';
import {
  getSMEIRPConfig,
  getSMInterferenceFields,
  getSMPowerConfig,
  smUserPowerFields,
} from './config-utils';
import { antenna } from './PMP450ViewConfig';

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'sm_equipment',
  component: NDEquipmentPanel,
  fields: [band, product, regulation],
};

const productPanel: PMPFormPanel = {
  kind: 'product',
  title: 'Product Configuration',
  name: 'sm_product',
  component: NDEquipmentPanel,
  fields: [
    {
      attrName: 'bandwidth',
      getter: 'radios.0.equipment.bandwidth',
    },
    {
      ...smRangeBaseConfig,
      editable: false,
    },
    {
      attrName: 'optimisation',
      getter: 'radios.0.equipment.optimisation',
    },
    {
      label: 'Sync',
      attrName: 'sync',
      getter: 'radios.0.equipment.sync',
    },
    {
      attrName: 'symmetry',
      getter: 'radios.0.equipment.symmetry',
    },
    {
      attrName: 'dual_payload',
      getter: 'radios.0.equipment.dual_payload',
      refreshesChoices: true,
    },
    {
      attrName: 'highest_mod_mode',
      getter: 'radios.0.equipment.highest_mod_mode',
      refreshesChoices: true,
    },
    {
      attrName: 'lowest_ethernet_mode',
      getter: 'radios.0.equipment.lowest_ethernet_mode',
      refreshesChoices: true,
    },
  ],
};

const powerPanel: PMPFormPanel = {
  kind: 'power',
  title: 'Power',
  name: 'sm_power',
  component: NDPowerPanel,
  fields: [
    {
      ...gasGroup,
      componentProps({ formGetter }) {
        let gasGroup = formGetter('radios.0.power.gas_group');
        if (!GasGroupsSpectra.map((gg) => gg.value).includes(gasGroup)) {
          // reset when switching from PMP 450i ATEX/HAZLOC
          gasGroup = 'A';
        }
        return {
          options: GasGroupsSpectra,
          value: gasGroup,
        };
      },
    },
    getSMEIRPConfig(),
    getSMPowerConfig(),
    smUserPowerFields,
    getSMInterferenceFields(),
  ],
};

const PTP670HCMPViewConfig = {
  syncFormState,
  equipment,
  product: productPanel,
  antenna,
  power: powerPanel,
};
export default PTP670HCMPViewConfig;
