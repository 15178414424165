import L from 'leaflet';
import React from 'react';
import 'leaflet/dist/leaflet.css';
import { Lethargy } from 'lethargy';
import { featureCollection, point, bearingToAzimuth } from '@turf/helpers';
import booleanContains from '@turf/boolean-contains';
import pointToLineDistance from '@turf/point-to-line-distance';
import { baseMaps } from './leafletMapProviders';
import { store } from '../../store';
import { loadProject } from '../mainframe/mainframe.reducer';
import { createTileIndex, FEATURE_LAYERS, clusterFeatures } from './mapTiles';
import getGeoJsonLayerOptions from './getGeoJsonLayerOptions';
import getFeatureStyle from './getFeatureStyle';
import { createAPDragHandles } from './apHandles';
import { queryClient } from 'src/query-client';
import {
  createMapSite,
  createNetworkSiteAndAP,
  createSiteAndPMPLink,
  createPopupContent,
  createPTPLink,
  createSiteAndPTPLink,
  getLayers,
  getCanvas,
  showEditDeletePopup,
} from '../../utils/mapUtils';
import { uiSet } from '../mainframe/mainframe.reducer';
import additionalMessages from '../../messages';
import { styleAccessPoint } from './apFeatures';
import ViewshedUtils from './viewshedUtils';
import {
  toggleSelectionPause,
  resetSelectingViewsheds,
} from '../viewshed/viewshed.reducer';
import {
  DEFAULT_AFC_COORDS,
  setAfcCoordinates,
  setUserCoordinates,
  setUserName,
} from './map.reducer';
import bearing from '@turf/bearing';
import { distanceBetweenTwoPoints } from '../pmp/aplayout/AccessPointProperties';
import { postWithAuth, accessPointFeatureCollection } from '../../api';
import {
  desktopDistance,
  meterToAny,
  insideAntennaSector,
  supportsMesh,
} from 'src/utils/useful_functions';
import { createTowerLayers } from './towerTiles';
import { toast } from 'react-toastify';
import { Message } from 'semantic-ui-react';
import afcIcon from './../../images/afc-marker.svg';
import destination from '@turf/destination';

export const states = {
  MAP_SELECT: 'select',
  MAP_CREATE_NETWORK_SITE: 'create network site',
  MAP_CREATE_SUBSCRIBER_SITE: 'create subscriber site',
  MAP_CREATE_ACCESS_POINT: 'create access point',
  MAP_CREATE_PMP_LINK: 'create pmp link',
  MAP_CREATE_PTP_LINK: 'create ptp link',
  MAP_VIEWSHED_MODE: 'viewshed mode',
  VIEWSHED_SELECT_LOCATION: 'select location',
  MAP_DISTANCE_MEASURE: 'measure',
  MAP_CREATE_MESH_LINK: 'create mesh link',
  MAP_AFC_MODE: 'automated frequency coordination',
};

const MIN_MESH_DISTANCE = 4;
const MAX_MESH_DISTANCE = 301;

const afcIconWdith = 80; // doc size in pixels
const afcIconHeight = 100; // doc size in pixels

const afcMarkerIcon = new L.Icon({
  iconUrl: afcIcon,
  iconRetinaUrl: afcIcon,
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(afcIconWdith / 4, afcIconHeight / 4),
  iconAnchor: [afcIconWdith / 8, afcIconHeight / 4],
});

const createSmallAP = (map, feature, scale = 100) => {
  const y = map.getSize().y;
  // calculate number of pixels per metre
  const pixelsPerMetre = map
    .containerPointToLatLng([0, y])
    .distanceTo(map.containerPointToLatLng([1, y]));
  const ap = feature.properties;
  const kind = 'access_point'; // assume a single AP per end for now, particularly for 60 GHz
  if (scale !== 100) {
    return accessPointFeatureCollection(
      kind,
      [ap],
      (scale / 600) * pixelsPerMetre,
      'kilometers'
    ).features[0];
  } else {
    return accessPointFeatureCollection(
      kind,
      [ap],
      0.02 * pixelsPerMetre,
      'kilometers'
    ).features[0];
  }
};
export const fixLng = (lng) => {
  return (((lng % 360) + 540) % 360) - 180;
};

/**
 * Draw a rubber-band line in the map
 */
const drawDashedLine = (p1, p2, color = 'red') => {
  const line = new L.polyline([p1, p2], {
    color: color,
    dashArray: '5, 5',
    dashOffset: '0',
    strokeOpacity: 0,
  });
  return line;
};

export class PTPMode {
  localSiteId = null;
  remoteSiteId = null;
  localSiteCoords = null;
  remoteSiteCoords = null;

  constructor() {
    if (this.instance) {
      return this.instance;
    }
    this.instance = this;
  }

  hasBothSites() {
    return this.localSiteId != null && this.remoteSiteId != null;
  }

  distance() {
    if (!this.hasBothSites()) {
      return null;
    }

    return distanceBetweenTwoPoints(
      {
        latitude: this.localSiteCoords.lat,
        longitude: this.localSiteCoords.lng,
      },
      {
        latitude: this.remoteSiteCoords.lat,
        longitude: this.remoteSiteCoords.lng,
      }
    );
  }

  sectorsAligned() {
    return (
      insideAntennaSector(
        this.localSiteCoords.beamwidth,
        this.localSiteCoords.azimuth,
        this.localSiteCoords.lat,
        this.localSiteCoords.lng,
        this.remoteSiteCoords.lat,
        this.remoteSiteCoords.lng
      ) &&
      insideAntennaSector(
        this.remoteSiteCoords.beamwidth,
        this.remoteSiteCoords.azimuth,
        this.remoteSiteCoords.lat,
        this.remoteSiteCoords.lng,
        this.localSiteCoords.lat,
        this.localSiteCoords.lng
      )
    );
  }

  partialSectorsAligned(meshAps) {
    // if there are no aps to consider, default to true
    if (meshAps.length === 0) return true;

    const target = meshAps[0];
    const azimuth = target.properties.radios[0].antennas[0].azimuth;
    const beamwidth = target.properties.radios[0].antennas[0].beamwidth;
    const lat = target.properties.latitude;
    const lng = target.properties.longitude;

    // all parameters on this function relate to the remote sector
    return (
      insideAntennaSector(
        this.localSiteCoords.beamwidth,
        this.localSiteCoords.azimuth,
        this.localSiteCoords.lat,
        this.localSiteCoords.lng,
        lat,
        lng
      ) &&
      insideAntennaSector(
        beamwidth,
        azimuth,
        lat,
        lng,
        this.localSiteCoords.lat,
        this.localSiteCoords.lng
      )
    );
  }

  reset = () => {
    this.localSiteCoords = null;
    this.remoteSiteId = null;
    this.remoteSiteCoords = null;
    this.localSiteId = null;
    getCanvas().handlePTPRubberBandEvents(false);
  };
}

export default class Canvas {
  state = states.MAP_SELECT;
  // Dictionary containing the key: coordinates for
  // each of the Leaflet GridLayer tiles that have been drawn
  tilesInView = {};
  // Stores the state of the user selection in the
  // visible layer control
  #visibleLayers = getLayers();
  // geojsonvt R*-Tree containing all of the project features
  #tileIndex = null;
  // Dictionary mapping the feature ID to the feature GeoJSON
  // Used when finding the objects in a given location
  // from the tileIndex, which only returns object IDs,
  // but we need to get the actual feature
  #featureCache = null;
  // Dictionary of drawn feature IDs.
  // Used when filtering the list based
  // on the map bounds
  // Use a dictionary rather than a list for
  // look-up speed
  drawnFeatures = null;
  // Leaflet GeoJSON layers
  // One for each feature kind that is visible
  // in the map
  #geoJSONLayers = {};
  // Map click listener function.
  // Can be set to create a site, etc.
  #clickListener = null;
  // Map mouse move listener function.
  //Used to draw the rubber-band line in the measure mode.
  #moveListener = null;
  // The rubber-band Leaflet layer
  #rubberBand = null;
  // User filter regex string from the header panel
  #filter = '';
  #filterRe = null;
  showNetworkSiteLabels = true;
  showSubscriberSiteLabels = true;
  // Used to store the current map selection
  // and any other items at the same hit point
  #selection = null;
  // This will be set to a feature which will get selected
  // after a redraw so that the selection isn't cleared onmoveend.
  // Used when centring on a feature.
  delayedSelection = null;
  #availableSelection = null;
  #apDragHandleLayer = null;
  ptpMode = null;
  meshMode = null;
  towerLayers = {};
  apScale = 100;
  afcMarker = null;

  /**
   * Singleton representing a Leaflet Map Canvas
   *
   * @constructor
   */
  constructor(formatMessage) {
    const instance = this.constructor.instance;
    if (instance) {
      return instance;
    }
    this.formatMessage = formatMessage;
    this.#resetTileIndex();
    this.constructor.instance = this;
  }

  get networkSiteLayer() {
    return this.#geoJSONLayers.network_site;
  }

  get subscriberSiteLayer() {
    return this.#geoJSONLayers.subscriber_site;
  }

  #resetTileIndex = (resetState = true) => {
    if (this.projectTileLayer) {
      this.projectTileLayer.remove();
      this.projectTileLayer = null;
    }
    this.#tileIndex = null;
    this.#featureCache = null;
    // if (resetState) {
    //   this.resetState();
    // }
  };

  /**
   * Initialise the map canvas.
   *
   * This will initialise the Leaflet canvas and
   * create the layer control.
   * This should only be called once.
   */
  initialise = () => {
    if (window?.__LP_map?.map) {
      // Prevent "Error: Map container is already initialized."
      try {
        window.__LP_map.map.remove();
      } catch (err) {
        console.error('Exception on map remove', err);
      }
    }
    window.__LP_map = this;

    this.#defineNetworkLayer();

    // Prevent inertia scrolling when zooming on the map
    const lethargy = new Lethargy(7, 50, 0.05);
    const isInertialScroll = (e) => lethargy.check(e) === false;

    L.Map.ScrollWheelZoom.prototype._onWheelScroll = function (e) {
      L.DomEvent.stop(e);
      if (isInertialScroll(e)) return;

      this._delta += L.DomEvent.getWheelDelta(e);
      this._lastMousePos = this._map.mouseEventToContainerPoint(e);
      this._performZoom();
    };

    // Load the map
    const map = L.map('map', {
      keyboard: false,
      worldCopyJump: true,
      preferCanvas: true,
      //   zoomAnimation: false,
      //   maxBounds: [
      //     [-90, -180],
      //     [90, 180],
      //   ],
      zoomControl: false,
    }).setView([0, 0], 2);
    this.map = map;

    this.#initBaseLayers(map);

    // Initialise the Crown Castle and American Tower layers.
    // This will update the "towerLayers" property.
    createTowerLayers(this);

    this.viewshed = new ViewshedUtils(this);

    // Restrict the zoom level to stop them zooming out too far
    // since we have set the map bounds to prevent the map
    // from scrolling out of the display range
    map.setMinZoom(2);

    // During debug we want the project to automatically reload
    // after init so that the map displays the correct features
    const pid = sessionStorage.getItem('cn.lp.projectId');
    const pname = sessionStorage.getItem('cn.lp.projectName');
    if (pid && pname) {
      store.dispatch(
        loadProject({
          projectId: pid,
          projectName: pname,
        })
      );
    }
  };

  /**
   * Define the L.GridLayer that is used to tile the network map features
   */
  #defineNetworkLayer = () => {
    // Create the GridLayer which requests the visible tiles
    L.GridLayer.NetworkLayer = L.GridLayer.extend({
      // Create map tiles on demand
      createTile: this.#createNetworkLayerTile,
      // Clean up map tiles when they go out of view
      _removeTile: this.#removeNetworkLayerTile,
      updateWhenZooming: false,
      updateWhendle: true,
    });

    L.gridLayer.NetworkLayer = function (opts) {
      return new L.GridLayer.NetworkLayer(opts);
    };
  };

  #bindKeyboard = () => {
    document.addEventListener('keydown', this.onKeyDown);
  };

  #unbindKeyboard = () => {
    document.removeEventListener('keydown', this.onKeyDown);
  };

  /**
   * Bind the map events
   *
   * @param {Object} map - Leaflet map instance
   */
  #bindMapEvents = (isOn) => {
    if (isOn) {
      this.map.on('mouseover', this.#bindKeyboard);
      this.map.on('mouseout', this.#unbindKeyboard);
      document.addEventListener('keyup', this.onKeyUp);
      this.map.on('moveend', this.onMoveEnd);
      this.map.on('contextmenu', (e) => {});
    } else {
      this.map.off('mouseover', this.#bindKeyboard);
      this.map.off('mouseout', this.#unbindKeyboard);
      document.removeEventListener('keyup', this.onKeyUp);
      this.map.off('moveend', this.onMoveEnd);
      this.map.off('contextmenu');
    }
  };

  /**
   * Initilise the map base layers
   *
   * @param {Object} map - Leaflet map instance
   *
   * Initialise the base layers for the map and create the map
   * baselayer control that is used to switch layer.
   */
  #initBaseLayers = (map) => {
    // Set the map layer controls
    map._ctrlPanel = new L.control.layers(
      baseMaps,
      {},
      {
        position: 'topright',
      }
    ).addTo(map);
    // Add a scale control
    L.control.scale({ position: 'bottomright' }).addTo(map);
    // Add a zoom control at the desired position
    L.control
      .zoom({
        position: 'bottomright',
      })
      .addTo(map);

    // update the map layer when the user changes a layer selection
    map.on('baselayerchange', function (e) {
      const layerName = e.layer.options.displayLabel;
      localStorage.setItem('cn.lp.mapLayer', layerName);
    });

    // Store the user's preferred layer in the localStorage
    // so that we show the preferred layer every time they
    // visit the site.
    let defaultLayer = localStorage.getItem('cn.lp.mapLayer');
    if (!defaultLayer || !baseMaps[defaultLayer]) {
      // Default to the topo map
      defaultLayer = 'Map';
      localStorage.setItem('cn.lp.mapLayer', 'Map');
    }
    baseMaps[defaultLayer].addTo(map);
  };

  /**
   * Return true if the labels are visible for the feature kind
   *
   * @param {String} kind  - The feature kind, e.g. "network_site", "subscriber_site"
   */
  visibleLabels = (kind) => {
    return {
      network_site: this.showNetworkSiteLabels,
      subscriber_site: this.showSubscriberSiteLabels,
    }[kind];
  };

  /**
   * Set the visible feature layers in the map
   *
   * @param {Object} layers  - Object containing layer name and state
   */
  setVisibleLayers = (layers) => {
    this.#visibleLayers = layers;
    this.#draw();
  };

  /**
   * Delete the pop-up from the map and clean up the edit event handler
   */
  #removePopup = () => {
    const mapInfo = document.getElementById('map-popup-container');
    if (mapInfo) {
      mapInfo.style.display = 'none';
    }
    if (window.__LP_map_edit) {
      delete window.__LP_map_edit;
    }
  };

  /**
   * Draw the feature layers in the map
   */
  #draw = (clearSelection = true) => {
    if (clearSelection && !this.delayedSelection) {
      this.setMultipleSelection(null, null);
    }
    this.drawnFeatures = {};

    // build a dict of the visible objects
    // for the tiles that are visible at this zoom level
    const allFeatures = Object.values(this.tilesInView)
      .map(this.#getTileFeatures)
      .reduce((acc, cv) => {
        Object.keys(cv).forEach((kind) => {
          if (!acc[kind]) {
            acc[kind] = [];
          }
          acc[kind].push(...cv[kind]);
        });
        return acc;
      }, {});

    // Delete any old layers and create
    // a new GeoJSON layer for each of the visible
    // object kinds.
    FEATURE_LAYERS.forEach((kind) => {
      const oldLayer = this.#geoJSONLayers[kind];
      if (oldLayer) {
        this.map.removeLayer(oldLayer);
        oldLayer.remove();
        delete this.#geoJSONLayers[kind];
      }
      if (this.#visibleLayers[kind]) {
        const featureArray = allFeatures[kind];
        if (featureArray) {
          const newLayer = new L.geoJSON(
            featureCollection(featureArray),
            getGeoJsonLayerOptions(kind)
          );
          this.map.addLayer(newLayer);
          this.#geoJSONLayers[kind] = newLayer;
          this.bindLayerEvents(kind, newLayer);
        }
      }
    });

    store.dispatch(
      uiSet({
        mapBounds: this.#getMapBounds(),
      })
    );
  };

  draw() {
    // public access for draw
    this.#draw();
  }

  bindLayerEvents = (kind, layer) => {
    if (layer) {
      layer.off('click', this.createAPSiteClickHandler);
      layer.off('click', this.createSubscriberSiteClickHandler);
      layer.off('click', this.createPTPLinkClickHandler);
      layer.off('click', this.createMeshLinkClickHandler);
    }

    if (
      layer &&
      kind === 'network_site' &&
      this.state === states.MAP_CREATE_ACCESS_POINT
    ) {
      // Bind the Create AP handler to the network site click event
      layer.on('click', this.createAPSiteClickHandler);
    } else if (
      layer &&
      kind === 'network_site' &&
      this.state === states.MAP_CREATE_PTP_LINK
    ) {
      layer.on('click', this.createPTPLinkClickHandler);
    } else if (
      layer &&
      kind === 'subscriber_site' &&
      this.state === states.MAP_CREATE_PMP_LINK
    ) {
      layer.on('click', this.createSubscriberSiteClickHandler);
    } else if (
      layer &&
      kind === 'access_point' &&
      this.state === states.MAP_CREATE_MESH_LINK
    ) {
      layer.on('click', this.createMeshLinkClickHandler);
    }
  };

  /**
   * Handle leaflet map keydown events
   *
   * Used to reset the map, start creating sites,
   * etc.
   */
  onKeyDown = (e) => {
    // Reset the map state when the user presses Escape
    const key = e.key?.toLowerCase();
    if (key === 'escape') {
      if (this.state === states.VIEWSHED_SELECT_LOCATION) {
        store.dispatch(toggleSelectionPause());
      } else {
        this.resetState(true);
      }
    } else if (key === 'f') {
      // F - Fit
      this.fit();
    }
  };

  /**
   * Handle leaflet map keyup events
   *
   * Used to toggle between multiple selections
   */
  onKeyUp = (e) => {
    // Reset the map state when the user presses Escape
    if (this.#availableSelection && this.#availableSelection.length > 1) {
      const key = e.key.toLowerCase();
      let direction = 0;
      if (key === 'arrowup') {
        direction = 1;
      } else if (key === 'arrowdown') {
        direction = -1;
      } else {
        return;
      }
      const currentSel = this.#selection;
      // clearAPSelection(old_sel);
      let index = this.#availableSelection.indexOf(currentSel) + direction;
      const numFeatures = this.#availableSelection.length;
      if (index >= numFeatures) {
        index = 0;
      } else if (index < 0) {
        index = numFeatures - 1;
      }

      this.setMultipleSelection(
        this.#availableSelection[index],
        this.#availableSelection
      );

      // var num_features = hitlist.length;
    }
  };

  /**
   * Handle map moveend events
   */
  onMoveEnd = (e) => {
    this.#draw();
    setTimeout(() => {
      if (this.#geoJSONLayers['subscriber_site']) {
        this.map.removeLayer(this.#geoJSONLayers['subscriber_site']);
        this.map.addLayer(this.#geoJSONLayers['subscriber_site']);
      }
      if (this.#geoJSONLayers['network_site']) {
        this.map.removeLayer(this.#geoJSONLayers['network_site']);
        this.map.addLayer(this.#geoJSONLayers['network_site']);
      }
    }, 200);

    if (this.delayedSelection) {
      this.setMultipleSelection(this.delayedSelection, null);
    }
  };

  /**
   * Set the map filter string
   * @param {String} filter  - The filter string
   */
  setFilter = (filter) => {
    this.#filter = filter;
    if (filter.length > 0) {
      this.#filterRe = new RegExp(filter, 'i');
    } else {
      this.#filterRe = null;
    }
    this.#draw();
  };

  /**
   * Display features on the map.
   *
   * @param {Object} networkSites       - Network sites FeatureCollection
   * @param {Object} subscriberSites    - Subscriber sites FeatureCollection
   * @param {Object} ptpLinks            - PTP Links FeatureCollection
   * @param {Object} accessPoints        - Access Point FeatureCollection
   * @param {Object} pmpLinks            - PMP Links FeatureCollection
   * @param {Boolean} fit                - Sets the map bounds to fit the shapes when true
   */
  display = (
    networkSites,
    subscriberSites,
    ptpLinks,
    accessPoints,
    meshLinks,
    pmpLinks,
    fit = true
  ) => {
    try {
      this.#bindMapEvents(false);

      this.#resetTileIndex();

      const [tileIndex, features] = createTileIndex(
        networkSites,
        subscriberSites,
        ptpLinks,
        accessPoints,
        meshLinks,
        pmpLinks
      );

      this.#tileIndex = tileIndex;
      this.#featureCache = features;
      this.#createDataLayers();
      if (fit) {
        this.fit();
        // } else {
        //   if (this.projectTileLayer) {
        //     this.map.removeLayer(this.projectTileLayer);
        //     this.map.addLayer(this.projectTileLayer);
        //   }
      }
      this.#bindMapEvents(true);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Fit the map to the network.
   */
  fit = () => {
    try {
      if (this.#featureCache) {
        const filter = this.#filter;

        let allFeatures = Object.values(this.#featureCache);
        let exp = null;
        if (filter.length > 0) {
          try {
            exp = new RegExp(filter, 'i');
          } catch (e) {
            //   console.warn(e);
          }
        }
        if (exp) {
          allFeatures = allFeatures.filter(
            (f) =>
              this.#visibleLayers[f.properties.kind] &&
              exp.test(f.properties.name)
          );
        } else {
          allFeatures = allFeatures.filter(
            (f) => this.#visibleLayers[f.properties.kind]
          );
        }
        if (allFeatures.length > 0) {
          const fc = featureCollection(allFeatures);
          this.map.fitBounds(L.geoJson(fc).getBounds());
          return;
        }
      }
      this.map.setZoom(2);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Zoom the map to fit the item.
   *
   * @param {Object} item  - Object containing the id and name of the feature to centerOn
   */
  centerOn = (item) => {
    const { map } = this;
    const feature = this.#featureCache[`${item.kind}-${item.id}`];
    this.delayedSelection = feature;
    if (feature) {
      let fc;
      if (item.kind.endsWith('site')) {
        // ideally we would use turf.buffer or turf.circle here, but we
        // don't want the dependency
        const distance = 0.05;
        const options = { units: 'kilometers' };
        const destinations = [0, 90, 180, 270].map((bearing) =>
          destination(feature, distance, bearing, options)
        );
        fc = featureCollection([feature, ...destinations]);
      } else {
        fc = featureCollection([feature]);
      }
      const bounds = L.geoJson(fc).getBounds();
      map.fitBounds(bounds);
    }
  };

  /**
   * Return an array of features that can be found at the coordinates.
   *
   * If the kind is provided then it will only return objects that have
   * the same kind. If kind is null/undefined then any object kind will
   * be matched.
   *
   * Returns an empty list if no items found.
   *
   * @param number lat  - Latitude
   * @param number lng  - Longitude
   * @param String kind - The object kind to consider for the hit test, "access_point", etc.
   */

  hittest = (lat, lng, kind) => {
    let items = [];
    if (
      (this.state === states.MAP_SELECT ||
        this.state === states.MAP_DISTANCE_MEASURE ||
        this.state === states.MAP_CREATE_MESH_LINK) &&
      this.#featureCache
    ) {
      const testPoint = point([lng, lat]);
      Object.values(this.#featureCache).forEach((f) => {
        if (!kind || kind === f.properties.kind) {
          const geomType = f.geometry.type;
          if (geomType === 'Point') {
            const [fLng, fLat] = f.geometry.coordinates;
            if (
              Math.abs(fLng - lng) < 0.00001 &&
              Math.abs(fLat - lat) < 0.00001
            ) {
              items.push(f);
            }
          } else if (geomType === 'Polygon') {
            // const convexhull = convex(f);
            let apFeature = f;
            if (this.state === states.MAP_CREATE_MESH_LINK) {
              apFeature = createSmallAP(this.map, f);
            }
            if (booleanContains(apFeature, testPoint)) {
              items.push(f);
            }
          } else if (geomType === 'LineString') {
            // Assume the link is hit if within 10m
            if (
              pointToLineDistance(testPoint, f, {
                units: 'kilometers',
              }) < 0.01
            ) {
              items.push(f);
            }
          } else {
            console.error('Unknown geometry type:', geomType);
          }
        }
      });
    }
    return items;
  };

  /**
   * Update the feature style to show if the feature is selected or not.
   *
   * @param {Object} feature     - GeoJSON feature
   * @param {Boolean} isSelected - Is the feature selected or not
   *
   * */
  #restyleFeature = (feature, isSelected) => {
    const style = getFeatureStyle(feature, isSelected);
    if (!style) {
      return;
    }

    this.map.eachLayer((f) => {
      if (f?.feature?.id === feature?.id) {
        f.setStyle(style);
      }
    });
  };

  drawTemporaryFeature = (feature) => {
    const baseLayer = localStorage.getItem('cn.lp.mapLayer') || 'Map';
    const canvas = this;
    const colors = [
      '#1B1C1D',
      '#2D2E2F',
      //   '#3F4041',
      //   '#515253',
      '#636465',
      //   '#757677',
      '#98999A',
      //   '#868788',
      '#AAABAC',
      //   '#BCBDBE',
      '#CECFD0',
      '#E0E1E3', // hack to make it appear lighter for longer
      '#E0E1E2',
    ];
    const dark = colors[0];
    const light = colors[colors.length - 1];
    const color =
      {
        Map: dark,
        Satellite: light,
        'Dark Gray': light,
        'Light Gray': dark,
      }[baseLayer] || light;

    let direction = 1;

    const setFlash = (obj) => {
      obj.on('add', ({ target }) => {
        obj._refreshTimer = setInterval(() => {
          let index = 0;
          if (target?.options?.style) {
            // line
            index = colors.indexOf(target.options.style.color);
          } else {
            // marker
            index = colors.indexOf(target.defaultOptions.color);
          }
          if (index === colors.length - 1) {
            direction = -1;
          } else if (index === 0) {
            direction = 1;
          }
          const newColor = colors[index + direction];
          target.setStyle({ color: newColor });
          if (target?.options?.style) {
            // line
            target.options.style.color = newColor;
          } else {
            // marker
            target.defaultOptions.color = newColor;
          }
        }, 70);
      });

      obj.on('remove', () => {
        clearInterval(obj._refreshTimer);
        delete obj._refreshTimer;
        canvas.message('');
      });
    };

    const nodeMarker = (feature, latlng) => {
      const marker = new L.circleMarker(latlng, {
        color,
        fillColor: '#e0e1e2',
        fillOpacity: 1,
        radius: 5,
        // pane: NETWORK_PANE,
      });
      return marker;
    };

    const newLayer = new L.geoJSON(feature, {
      style: {
        color: color,
        weight: 1,
        dashArray: '5, 5',
        // pane: NETWORK_PANE,
      },
      pointToLayer: nodeMarker,
    });

    setFlash(newLayer);

    this.map.addLayer(newLayer);
    this.#delayedRemoveLayer(newLayer);
  };

  #delayedRemoveLayer = (layer) => {
    setTimeout(() => {
      if (layer && this.map.hasLayer(layer)) {
        this.map.removeLayer(layer);
      }
    }, 2500);
  };

  setAPDragHandles = (handleLayer) => {
    if (this.#apDragHandleLayer) {
      this.map.removeLayer(this.#apDragHandleLayer);
    }
    this.#apDragHandleLayer = handleLayer;
    if (handleLayer) {
      this.map.addLayer(handleLayer);
    }
  };

  /**
   * Return the opposite beamwidth drag handle
   *
   * @param {String} name - name of the current drag handle
   *
   * If the current name is "c1" then it will return the "c2" layer.
   * If the current name is "c2" then it will return the "c1" layer.
   * Anything else will return null.
   */
  getOppositeHandle = (name) => {
    if (this.#apDragHandleLayer && (name === 'c1' || name === 'c2')) {
      const layerName = name === 'c1' ? 'c2' : 'c1';
      const layers = this.#apDragHandleLayer
        .getLayers()
        .filter((l) => l.feature.properties.name === layerName);
      if (layers) {
        return layers[0];
      }
    }
  };

  get selection() {
    return this.#selection;
  }

  /**
   * Set the current selection after performing the hittest
   *
   * @param {Object} selection   - null or primary GeoJSON feature that has been selected
   * @param {Object[]} selection - null or array of GeoJSON features that
   *                               are available at the same point.
   *                               This will also contain the selection.
   */
  setMultipleSelection = (selection, availableSelection) => {
    if (this.state === states.MAP_SELECT) {
      //   if (this.#selection) {
      //     this.#restyleFeature(this.#selection, false);
      //   }
      this.setAPDragHandles(null);
      if (!selection && this.delayedSelection) {
        selection = this.delayedSelection;
        this.delayedSelection = null;
      }

      const oldSelection = this.#selection;
      this.#selection = selection;
      if (availableSelection) {
        this.#availableSelection = availableSelection;
      } else {
        this.#availableSelection = null;
      }
      if (this.#selection) {
        // this.#restyleFeature(this.#selection, true);
        if (
          this.#selection.properties.kind === 'access_point' &&
          store.getState().mainFrame.permissionWrite
        ) {
          // trigger the AP drag handles
          // TODO broken due to api changes (restructuring of aps/ends)
          if (!this.#geoJSONLayers.access_point) {
            return;
          }
          const apLayers = this.#geoJSONLayers.access_point.getLayers();
          let oldFeature = null;
          let feature = null;
          apLayers.forEach((layer) => {
            if (layer.feature.properties.id === oldSelection?.properties.id) {
              oldFeature = layer;
            } else if (
              layer.feature.properties.id === selection.properties.id
            ) {
              feature = layer;
            }
          });

          if (oldFeature) {
            oldFeature.setStyle(styleAccessPoint(oldFeature.feature, false));
          }
          if (feature) {
            feature.setStyle(styleAccessPoint(feature.feature, true));
          }
          this.#draw(false);
          this.setAPDragHandles(createAPDragHandles(this.#selection));
        }
        this.showInfo(this.#selection);
      } else {
        this.#removePopup();
      }
      if (this.#availableSelection?.length > 1) {
        this.message(this.formatMessage(additionalMessages.useArrowKeys));
      } else {
        this.message(null);
      }
    }
  };

  /**
   * Display an info window for the selected feature
   *
   * @param {Object} feature  - GeoJSON feature
   */
  showInfo = (feature) => {
    const mapDiv = document.getElementById('map');
    if (!mapDiv) {
      return;
    }
    let mapInfo = document.getElementById('map-popup-container');
    if (!mapInfo) {
      mapInfo = document.createElement('div');
      // Info message class
      mapInfo.setAttribute('class', 'ui message basic visible no-border');
      mapInfo.setAttribute('id', 'map-popup-container');
    } else {
      mapInfo.style.display = '';
      mapInfo.setAttribute('class', 'ui message basic visible no-border');
    }
    mapInfo.innerHTML = `
    <div class="map-tooltip-loading"><i
    id="map-popup-loading"
    class="spinner loading icon"
    title="${this.formatMessage(additionalMessages.clickToClose)}"
    ></i> Loading..</div>
    `;
    mapDiv.insertAdjacentElement('beforebegin', mapInfo);
    createPopupContent(feature).then((content) => {
      mapInfo.innerHTML = `    
    ${content}
    `;
      mapDiv.insertAdjacentElement('beforebegin', mapInfo);
    });
  };

  /**
   * Reset events and the rubberband when the measure mode is disabled
   */
  resetMeasure = () => {
    if (this.#moveListener) {
      this.map.off('mousemove', this.#moveListener);
      this.#moveListener = null;
    }
    if (this.#rubberBand) {
      this.map.removeLayer(this.#rubberBand);
      this.#rubberBand = null;
    }
    this.map.dragging.enable();
  };

  /**
   * Reset the state of the map and unbind any temporary event handles
   */
  resetState = (redraw = false) => {
    this.setState(states.MAP_SELECT);
    this.resetMeasure();
    this.#setCrosshairDisabledAllLayers(false);
    if (this.map) {
      this.map.off('mousedown', this.measureHandler);
      this.map.off('mousemove', this.trackCoords);
      this.handlePTPRubberBandEvents(false);
    }

    if (this.#clickListener) {
      this.map.off('click', this.#clickListener);
      this.#clickListener = null;
    }
    if (this.afcMarker) {
      this.map.removeLayer(this.afcMarker);
      this.afcMarker = null;
    }
    if (this.map && this.map._container) {
      L.DomUtil.removeClass(this.map._container, 'leaflet-crosshair');
      L.DomUtil.removeClass(this.map._container, 'leaflet-crosshair-disabled');
    }
    this.setMultipleSelection(null, null);
    this.message(null);
    if (redraw) {
      this.#draw();
    }
  };

  /**
   * Track the mouse cursor coordinates when the mouse moves
   */
  trackCoords = (event) => {
    const { lat, lng } = event.latlng;
    store.dispatch(setUserCoordinates({ lat, lng: fixLng(lng) }));
  };

  /**
   * Start to create network sites in the map.
   */
  createNetworkSite = () => {
    this.#activateClickHandler('network_site');
    this.setState(states.MAP_CREATE_NETWORK_SITE);
    this.#draw();
    store.dispatch(setUserName(''));
    const { lat, lng } = this.map.getCenter();
    store.dispatch(setUserCoordinates({ lat, lng: fixLng(lng) }));
    this.map.on('mousemove', this.trackCoords);
  };

  /**
   * activate afc mode in the map.
   */
  activateAFCMode = () => {
    const defaultCoords = DEFAULT_AFC_COORDS;
    this.map.fitBounds([
      [49.34579, -125.0],
      [24.39631, -66.93457],
    ]);
    // this.map.setZoomAround(defaultCoords, 4);
    this.setAfcMarkerOnMap(defaultCoords.lat, defaultCoords.lng);

    const onclick = (lat, lng) => {
      this.setAfcMarkerOnMap(lat, lng);
    };
    this.#activateClickHandler('', onclick, false);
    store.dispatch(
      setAfcCoordinates({ lat: defaultCoords.lat, lng: defaultCoords.lng })
    );
  };

  setAfcMarkerOnMap = (lat, lng) => {
    if (this.afcMarker) {
      this.afcMarker.setLatLng([lat, lng]);
    } else {
      var newMarker = new L.marker(
        { lat: lat, lng: lng },
        {
          icon: afcMarkerIcon,
        }
      );
      this.afcMarker = newMarker;
      newMarker.addTo(this.map);
    }

    store.dispatch(setAfcCoordinates({ lat, lng }));
  };

  /**
   * Start to create subscriber sites in the map.
   */
  createSubscriberSite = () => {
    this.#activateClickHandler('subscriber_site');
    this.setState(states.MAP_CREATE_SUBSCRIBER_SITE);
    this.#draw();
    store.dispatch(setUserName(''));
    const { lat, lng } = this.map.getCenter();
    store.dispatch(setUserCoordinates({ lat, lng: fixLng(lng) }));
    this.map.on('mousemove', this.trackCoords);
  };

  /**
   * Start to create an access point in the map.
   */
  createAccessPoint = () => {
    // create the map click handler first
    this.#activateClickHandler('network_site', createNetworkSiteAndAP);
    // now add the click handler to all of the
    // existing network site features
    this.setState(states.MAP_CREATE_ACCESS_POINT);
    this.#draw();
    this.bindLayerEvents('network_site', this.#geoJSONLayers.network_site);
  };

  /**
   * Start to create a PMP link in the map.
   */
  createPMPLink = () => {
    // create the map click handler first
    this.#activateClickHandler('subscriber_site', createSiteAndPMPLink);
    this.setState(states.MAP_CREATE_PMP_LINK);
    this.#draw();
    this.bindLayerEvents(
      'subscriber_site',
      this.#geoJSONLayers.subscriber_site
    );
  };

  /**
   * Start to create a PTP link in the map.
   */
  createPTPLink = () => {
    // create the map click handler first
    this.ptpMode = new PTPMode();
    this.#activateClickHandler('network_site', createSiteAndPTPLink);
    this.setState(states.MAP_CREATE_PTP_LINK);
    const { lat, lng } = this.map.getCenter();
    store.dispatch(setUserCoordinates({ lat, lng: fixLng(lng) }));
    this.map.on('mousemove', this.trackCoords);
    this.bindLayerEvents('network_site', this.#geoJSONLayers.network_site);
  };

  meshFindAps(lat, lng) {
    return this.hittest(lat, lng, 'access_point').filter(
      (f) =>
        f.properties?.id !== this.meshMode.localSiteId &&
        this.#shouldDisplayFeature(f)
    );
  }

  meshApLatLng(features) {
    if (features.length > 0) {
      const feature = features[0];
      const lat =
        feature.properties?.latitude || feature.geometry?.coordinates[1];
      const lng =
        feature.properties?.longitude || feature.geometry?.coordinates[0];
      return [lat, lng];
    }
    return null;
  }

  #setCrosshairDisabledAllLayers(flag) {
    // flag: boolean
    let fn, fnInverse;
    if (flag) {
      fn = L.DomUtil.addClass;
      fnInverse = L.DomUtil.removeClass;
    } else {
      fn = L.DomUtil.removeClass;
      fnInverse = L.DomUtil.addClass;
    }

    fnInverse(this.map._container, 'leaflet-crosshair');
    fn(this.map._container, 'leaflet-crosshair-disabled');
    this.map.eachLayer((layer) => {
      if (layer._container) fn(layer._container, 'leaflet-crosshair-disabled');
    });
  }

  displayPTPLinkLength = (e) => {
    const { prefs } = store.getState().mainFrame;
    const canvas = this;
    const map = this.map;

    let mode, meshAps;
    let lat = e.latlng.lat;
    let lng = e.latlng.lng;
    if (this.state === states.MAP_CREATE_MESH_LINK) {
      mode = 'meshMode';
      meshAps = this.meshFindAps(lat, lng);
      const latlng = this.meshApLatLng(meshAps);
      if (latlng != null) {
        [lat, lng] = latlng;
      }
    } else {
      mode = 'ptpMode';
    }

    const { localSiteCoords } = canvas[mode];
    this.map.dragging.disable();

    if (canvas.#rubberBand) {
      map.removeLayer(canvas.#rubberBand);
      canvas.#rubberBand = null;
    }

    const linelength = desktopDistance(
      localSiteCoords.lat,
      localSiteCoords.lng,
      lat,
      lng
    );

    let dashedLineColor = 'blue';
    if (
      mode === 'meshMode' &&
      (linelength < MIN_MESH_DISTANCE ||
        linelength > MAX_MESH_DISTANCE ||
        !this.meshMode.partialSectorsAligned(meshAps))
    ) {
      dashedLineColor = 'red';
      this.#setCrosshairDisabledAllLayers(true);
    } else {
      this.#setCrosshairDisabledAllLayers(false);
    }

    let tooltipContent = `Range: ${meterToAny(
      linelength,
      prefs.rangeUnits,
      3
    )} ${prefs.rangeUnits}`;

    canvas.#rubberBand = drawDashedLine(
      [localSiteCoords.lat, localSiteCoords.lng],
      [lat, lng],
      dashedLineColor
    );

    canvas.#rubberBand
      .addTo(map)
      .bindTooltip(tooltipContent, {
        interactive: false,
        opacity: 0.9,
        className: 'apHandleLabel',
        direction: 'top',
      })
      .openTooltip();
  };

  measureHandler = (e) => {
    this.resetMeasure();
    const { prefs } = store.getState().mainFrame;
    let latlngs = [];
    const canvas = this;
    const map = this.map;
    this.map.dragging.disable();
    let lat = e.latlng.lat;
    let lng = e.latlng.lng;

    const features = [
      ...this.hittest(lat, lng, 'network_site'),
      ...this.hittest(lat, lng, 'subscriber_site'),
    ];
    if (features?.length >= 1) {
      // Snap to the centre of the site
      const feature = features[0];
      lat = feature.properties?.latitude || feature.geometry?.coordinates[1];
      lng = feature.properties?.longitude || feature.geometry?.coordinates[0];
    }
    latlngs.push([lat, lng]);

    this.#moveListener = (e) => {
      let lat = e.latlng.lat;
      let lng = e.latlng.lng;
      if (canvas.#rubberBand) {
        map.removeLayer(canvas.#rubberBand);
        canvas.#rubberBand = null;
      }

      latlngs.push([lat, lng]);
      const start = latlngs[0];
      const end = latlngs[latlngs.length - 1];
      const p1 = point([start[1], start[0]]);
      const p2 = point([end[1], end[0]]);

      canvas.#rubberBand = drawDashedLine(start, end);
      const linelength = desktopDistance(start[0], start[1], end[0], end[1]);
      let tooltipContent = `Range: ${meterToAny(
        linelength,
        prefs.rangeUnits,
        3
      )} ${prefs.rangeUnits}`;
      const lineBearing = bearingToAzimuth(bearing(p1, p2));
      tooltipContent = tooltipContent.concat(`<br/>
        Bearing: ${lineBearing.toFixed(1)}&deg;
        `);
      canvas.#rubberBand
        .addTo(map)
        .bindTooltip(tooltipContent, {
          interactive: false,
          opacity: 0.9,
          className: 'apHandleLabel',
          direction: 'top',
        })
        .openTooltip();
    };
    this.map.on('mousemove', this.#moveListener);
    this.map.on('mouseup', (e) => {
      canvas.resetMeasure();
    });
  };

  /**
   * Start to enable the measure events in the map
   */
  activateMeasure = function (e) {
    this.resetState();
    this.setState(states.MAP_DISTANCE_MEASURE);
    this.#draw();
    L.DomUtil.addClass(this.map._container, 'leaflet-crosshair');
    this.map.on('mousedown', this.measureHandler);
  };

  /**
   * Start to create mesh link in the map.
   */
  createMeshLink = () => {
    this.meshMode = new PTPMode();
    this.resetState(false);
    this.setState(states.MAP_CREATE_MESH_LINK);
    this.#draw();
    L.DomUtil.addClass(this.map._container, 'leaflet-crosshair');
  };

  /*
   * Set the map state and update the mainframe reducer with the setting
   */
  setState = (state) => {
    // clear feature layer events
    if (state === states.MAP_SELECT) {
      Object.values(this.towerLayers).map((layer) => {
        layer.show(true);
      });
    } else {
      if (!(location.pathname === '/' || location.pathname === '/afc')) {
        const navigate = store.getState().mainFrame.navigate;
        if (navigate) navigate('/');
      }
      Object.values(this.towerLayers).map((layer) => {
        layer.show(false);
      });
    }
    this.bindLayerEvents('', null);
    this.state = state;
    try {
      store.dispatch(uiSet({ mapState: this.state }));
      store.dispatch(setAfcCoordinates(null));
    } catch (err) {
      // do nothing ;
    }
  };

  setApScale = (scale) => {
    this.apScale = scale;

    this.#draw();
  };

  /**
   * Bind the click event to create a site when the map is clicked
   *
   * The cursor changes to a crosshair and
   * each click creates a new site in the backend.
   */
  #activateClickHandler = (kindUrl, createFunc = null, reset = true) => {
    if (reset) {
      this.resetState();
    }
    L.DomUtil.addClass(this.map._container, 'leaflet-crosshair');
    const defaultHeight = store.getState().map.defaultHeight;
    if (defaultHeight < 0 || defaultHeight > 3000) {
      L.DomUtil.addClass(this.map._container, 'leaflet-crosshair-disabled');
    }
    //   shape_features.eachLayer(function (layer) {
    //     layer.feature.properties._preventClick = true;
    //   });
    this.#clickListener = (event) => {
      //   this.resetState();
      var latlng = event.latlng;
      var lng = fixLng(latlng.lng);
      L.DomEvent.preventDefault(event);
      L.DomEvent.stopPropagation(event);
      if (createFunc) {
        createFunc(latlng.lat, lng, null);
      } else {
        // default to creating a site
        createMapSite(latlng.lat, lng, kindUrl, (data) => {
          const { id, kind } = data ?? {};
          if (!id) return;

          // We need to create a transparent icon with 0 width and height
          // so that we will not have an empty space below the popup
          const transparentIcon = L.divIcon({
            iconSize: [0, 0],
          });

          const layer = L.marker([latlng.lat, lng], {
            icon: transparentIcon,
            opacity: 0,
          }).addTo(this.map);
          showEditDeletePopup(layer, id, kind);
        });
      }
    };
    this.map.on('click', this.#clickListener);
  };

  checkIfDuplicateMeshLink = () => {
    let existingMeshLinksCoords = [];
    const meshLinks = store.getState().mainFrame.meshLinks.features;
    const { localSiteCoords, remoteSiteCoords } = this.meshMode;
    meshLinks.forEach((el) => {
      const { properties } = el;
      const { loc_lat, loc_lng, rem_lat, rem_lng } = properties;
      existingMeshLinksCoords.push(`${loc_lat}${loc_lng}${rem_lat}${rem_lng}`);
      existingMeshLinksCoords.push(`${rem_lat}${rem_lng}${loc_lat}${loc_lng}`);
    });
    return existingMeshLinksCoords.includes(
      `${localSiteCoords.lat}${localSiteCoords.lng}${remoteSiteCoords.lat}${remoteSiteCoords.lng}`
    );
  };

  /**
   * Event hander for when the user clicks on an
   * existing network sites to create a new Mesh Link
   */

  createMeshLinkClickHandler = (event) => {
    if (this.state === states.MAP_CREATE_MESH_LINK) {
      L.DomEvent.preventDefault(event);
      L.DomEvent.stopPropagation(event);
      this.map.doubleClickZoom.disable();

      const { properties } = event.layer.feature;

      if (this.meshMode.localSiteId == null) {
        this.meshMode.localSiteId = properties.id;
        this.meshMode.localSiteCoords = {
          lat: properties.latitude,
          lng: properties.longitude,
          azimuth: properties.radios[0].antennas[0].azimuth,
          beamwidth: properties.radios[0].antennas[0].beamwidth,
        };
        this.handlePTPRubberBandEvents();
      } else {
        const { latlng } = event;
        const { lat, lng } = latlng;
        // ensure selected ap matches whats shown in the map
        const features = this.meshFindAps(lat, lng);

        if (features.length > 0) {
          const feature = features[0];

          if (feature.properties.id === this.meshMode.localSiteId) {
            // ensure user clicking the original site again does not try to
            // finalise the link
            return;
          }

          this.handlePTPRubberBandEvents(false);
          this.meshMode.remoteSiteId = feature.properties.id;
          this.meshMode.remoteSiteCoords = {
            lat: feature.properties.latitude,
            lng: feature.properties.longitude,
            azimuth: feature.properties.radios[0].antennas[0].azimuth,
            beamwidth: feature.properties.radios[0].antennas[0].beamwidth,
          };
        }
      }

      if (this.meshMode.hasBothSites()) {
        const distance = this.meshMode.distance();

        if (
          distance != null &&
          distance > MIN_MESH_DISTANCE &&
          distance < MAX_MESH_DISTANCE &&
          this.meshMode.sectorsAligned()
        ) {
          const data = {
            ap1_id: this.meshMode.localSiteId,
            ap2_ids: [this.meshMode.remoteSiteId],
          };
          const projectId = store.getState().mainFrame.projectId;
          if (!this.checkIfDuplicateMeshLink()) {
            postWithAuth(`project/${projectId}/mesh_links`, data).finally(
              () => {}
            );
          } else {
            toast(<Message error>{'Mesh link already exists'}</Message>, {
              autoClose: false,
            });
          }
        }

        this.meshMode = new PTPMode();

        this.map.doubleClickZoom.enable();
        this.#setCrosshairDisabledAllLayers(false);
      }
    } else {
      // this shouldn't be here - clear the events
      this.bindLayerEvents('', null);
    }
  };

  /**
   * Event hander for when the user clicks on an
   * Event handler for when the user clicks on an
   * existing network site to create a new AP
   */
  createAPSiteClickHandler = (event) => {
    if (this.state === states.MAP_CREATE_ACCESS_POINT) {
      L.DomEvent.preventDefault(event);
      L.DomEvent.stopPropagation(event);
      this.map.doubleClickZoom.disable();
      const { layer, latlng } = event;
      const { lat, lng } = latlng;
      const feature = layer.feature;
      createNetworkSiteAndAP(lat, lng, feature.properties.id);
      this.map.doubleClickZoom.enable();
    } else {
      // this shouldn't be here - clear the events
      this.bindLayerEvents('', null);
    }
  };

  handlePTPRubberBandEvents = (bind = true) => {
    if (bind) {
      this.map.on('mousemove', this.displayPTPLinkLength);
      this.map.on('mouseup', (e) => {
        this.resetMeasure();
      });
    } else {
      this.map.off('mousemove', this.displayPTPLinkLength);
      this.map.off('mouseup', (e) => {
        this.resetMeasure();
      });
      this.resetMeasure();
    }
  };

  createPTPLinkClickHandler = (event) => {
    if (this.state === states.MAP_CREATE_PTP_LINK) {
      L.DomEvent.preventDefault(event);
      L.DomEvent.stopPropagation(event);
      this.map.doubleClickZoom.disable();
      const { layer, latlng } = event;
      const { lat, lng } = latlng;
      const feature = layer.feature;
      const { properties } = feature;
      if (this.ptpMode.localSiteId == null) {
        this.ptpMode.localSiteId = properties.id;
        this.ptpMode.localSiteCoords = { lat, lng };
        this.handlePTPRubberBandEvents();
      } else {
        this.ptpMode.remoteSiteId = properties.id;
        this.ptpMode.remoteSiteCoords = { lat, lng };
      }
      if (
        this.ptpMode.localSiteId != null &&
        this.ptpMode.remoteSiteId != null
      ) {
        createPTPLink(this.ptpMode.localSiteId, this.ptpMode.remoteSiteId);
      }
    }
  };

  createSubscriberSiteClickHandler = (event) => {
    if (this.state === states.MAP_CREATE_PMP_LINK) {
      L.DomEvent.preventDefault(event);
      L.DomEvent.stopPropagation(event);
      this.map.doubleClickZoom.disable();
      const { layer, latlng } = event;
      const { lat, lng } = latlng;
      const feature = layer.feature;
      createSiteAndPMPLink(lat, lng, feature.properties.id);
      this.map.doubleClickZoom.enable();
    } else {
      // this shouldn't be here - clear the events
      this.bindLayerEvents('', null);
    }
  };

  selectViewshedLocations = (options) => {
    this.viewshed.options = options;
    this.viewshed.activateSelectLocations();
    this.message(
      this.formatMessage(additionalMessages.clickForViewsheds),
      false
    );
  };

  pauseSelectViewshedLocations = () => {
    this.viewshed.pauseSelectLocations();
  };

  resumeSelectViewshedLocations = () => {
    this.viewshed.resumeSelectLocations();
  };

  selectedViewshedLocations = () => {
    return this.viewshed.selection;
  };

  resetViewshedSelection = () => {
    this.viewshed.resetSelection();
  };

  removeViewshedMarker = (locationIndex, lat, lng) => {
    this.viewshed.removeMarker(locationIndex, lat, lng);
  };

  showViewshed = (id, image, bounds) => {
    this.viewshed.show(id, image, bounds);
  };

  hideViewshed = (id) => {
    this.viewshed.hide(id);
  };

  removeAllViewsheds = () => {
    this.viewshed.removeAll();
  };

  resetViewshedState = () => {
    this.resetViewshedSelection();
    store.dispatch(resetSelectingViewsheds());
  };

  /**
   * Create the tile data layers for each feature type.
   */
  #createDataLayers = () => {
    if (this.projectTileLayer) {
      this.map.removeLayer(this.projectTileLayer);
      this.projectTileLayer.remove();
      this.projectTileLayer = null;
    }

    // this.map.createPane(NETWORK_PANE);
    // this.map.getPane(NETWORK_PANE).style.zIndex = NETWORK_PANE_ZINDEX;
    // this.map.getPane(NETWORK_PANE).style.pointerEvents = 'none';

    this.projectTileLayer = new L.gridLayer.NetworkLayer({
      //   zIndex: NETWORK_PANE_ZINDEX,
      //   pane: NETWORK_PANE,
    });
    this.projectTileLayer.on('load', () => {
      this.#draw();
    });
    // Add the GridLayer to the map
    this.map.addLayer(this.projectTileLayer);
  };

  /**
   * Return an individual map tile (div) for the coords (x,y, z)
   *
   * If the tile contains map features then they will be drawn on the tile
   * as GeoJSON layers. 1 layer for each object kind.
   * Each layer will have styling that is specific to the layer.
   */
  #createNetworkLayerTile = (coords) => {
    const key = L.GridLayer.prototype._tileCoordsToKey(coords);
    this.tilesInView[key] = coords;
    return L.GridLayer.prototype.createTile.call(this.projectTileLayer, coords);
  };

  /**
   * Remove the Leaflet tile from the DOM.
   *
   * If the tile contains any GeoJSON layers then
   * they are removed from the map before removing
   * the tile.
   */
  #removeNetworkLayerTile = (key) => {
    if (this.tilesInView[key]) {
      delete this.tilesInView[key];
    }
    L.GridLayer.prototype._removeTile.call(this.projectTileLayer, key);
  };

  #getMapBounds = () => {
    const bounds = this.map.getBounds();
    const southWest = bounds.getSouthWest();
    const west = southWest.lng;
    const south = southWest.lat;
    const northEast = bounds.getNorthEast();
    const east = northEast.lng;
    const north = northEast.lat;
    return { north, south, east, west };
  };

  /**
   * Calculate if a single feature should be displayed.
   */
  #shouldDisplayFeature = (feature) => {
    if (feature.properties.hidden) {
      return false;
    }

    let result =
      this.#filterRe === null || this.#filterRe.test(feature.properties.name);

    if (this.state === states.MAP_CREATE_MESH_LINK) {
      const featureKind = feature.properties.kind;

      let meshAps = [];
      if (featureKind === 'access_point') {
        meshAps = supportsMesh(store.getState().mainFrame.accessPoints);
      }

      result =
        result &&
        (feature.properties.kind === 'mesh_link' ||
          (featureKind === 'access_point' &&
            meshAps.map((ap) => ap.id).includes(feature.properties.id)));
    }

    return result;
  };

  /**
   * Calculate a set of feature ids which should be displayed.
   * Useful when many features are being processed
   */
  #displayedFeaturesIndex(features) {
    let result = new Set();

    const hasFilter = this.#filterRe !== null;
    for (const feature of features) {
      let display = !hasFilter || this.#filterRe.test(feature.properties.name);

      if (this.state === states.MAP_CREATE_MESH_LINK) {
        const { properties } = feature;

        if (properties.kind === 'access_point') {
          display =
            display &&
            supportsMesh(store.getState().mainFrame.accessPoints)
              .map((ap) => ap.id)
              .includes(properties.id);
        } else if (
          properties.kind === 'subscriber_site' ||
          properties.kind === 'ptp_link' ||
          properties.kind === 'pmp_link'
        ) {
          display = false;
        }
      }

      if (display) {
        result.add(feature.id);
      }
    }

    return result;
  }

  #getTileFeatures = (coords) => {
    const apScale = this.apScale;
    const { x, y, z } = coords;
    const tileIndex = this.#tileIndex;
    if (!this.drawnFeatures) {
      this.drawnFeatures = {};
    }
    let drawnFeatures = this.drawnFeatures;
    let result = {};
    if (tileIndex) {
      // find the features for the tile from the tileIndex
      const index = tileIndex.getTile(z, x, y);
      const indexFeatures = index ? index.features : null;
      if (indexFeatures) {
        const featureArrays = {
          network_site: [],
          subscriber_site: [],
          ptp_link: [],
          access_point: [],
          mesh_link: [],
          pmp_link: [],
        };

        // Create the geoJSON FeatureCollection for the
        // individual features that are visible in this tile.
        // Group the features by kind.
        const displayedFeatures = this.#displayedFeaturesIndex(
          indexFeatures.map((f) => this.#featureCache[f.id])
        );
        indexFeatures.forEach((f) => {
          const id = f.id;
          if (!drawnFeatures[id]) {
            // keep track of the features that have been drawn at this zoom level
            // so that we only draw each object once
            const feature = this.#featureCache[id];
            if (displayedFeatures.has(id)) {
              drawnFeatures[id] = true;
              const kind = feature.properties.kind;
              const featureArray = featureArrays[kind];
              if (
                kind === 'access_point' &&
                this.state === states.MAP_CREATE_MESH_LINK &&
                (!this.#selection || id !== this.#selection.id)
              ) {
                featureArray.push(createSmallAP(this.map, feature));
              } else if (
                kind === 'access_point' &&
                apScale != 100 &&
                id !== this.#selection?.id
              ) {
                featureArray.push(createSmallAP(this.map, feature, apScale));
              } else {
                featureArray.push(feature);
              }
            }
          }
        });

        // Convert the features to a GeoJSON data layer on the map
        // that is specific to this tile.
        Object.keys(this.#visibleLayers).forEach((kind) => {
          if (this.#visibleLayers[kind]) {
            const features = featureArrays[kind];
            if (features?.length > 0) {
              const clusteredArray = clusterFeatures(features, z);
              result[kind] = clusteredArray;
            }
          }
        });
      }
    }
    return result;
  };

  /**
   * Display message floating above the map
   *
   * @param {String} msg  - The message to display
   */
  message = (msg, closeOnClick = true) => {
    const mapDiv = document.getElementById('map');
    if (!mapDiv) {
      return;
    }

    let msgDiv = document.getElementById('map-message');

    if (!msgDiv) {
      msgDiv = document.createElement('div');
      // Info message class
      msgDiv.setAttribute('class', 'ui success message');
      msgDiv.setAttribute('id', 'map-message');
      msgDiv.setAttribute(
        'title',
        this.formatMessage(additionalMessages.clickToClose)
      );
    } else {
      msgDiv.style.display = '';
    }

    if (closeOnClick) {
      msgDiv.setAttribute(
        'onclick',
        "document.getElementById('map-message').style.display='none';"
      );
    } else {
      msgDiv.removeAttribute('onclick');
    }

    msgDiv.innerHTML = msg;
    if (!msg) {
      msgDiv.style.display = 'none';
    }
    mapDiv.insertAdjacentElement('beforebegin', msgDiv);
  };
}
