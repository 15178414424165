import { get } from 'lodash';
import { Form } from 'semantic-ui-react';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import NDEquipmentPanel from '../../nd/NDEquipmentPanel';
import { PMPFormField, PMPFormPanel, PMPShowProps } from '../../nd/utils';
import NDAntennaPanel from '../../nd/NDAntennaPanel';
import {
  antennaSelection,
  azimuth,
  cableLoss,
  tilt,
} from '../../nd/configs/config-utils';

import { syncFormState } from '../utils';
import { round } from 'src/utils/useful_functions';
import NDPowerPanel from '../../nd/NDPowerPanel';
import {
  band,
  product,
  gasGroup,
  regulation,
  smRangeBaseConfig,
  antennaHeight,
  antennaHeightComponentProps,
  calculateLoss,
  cableChoices,
  cableLength,
  isPMP450v,
} from '../../config-utils';
import CustomTextField from 'src/components/controls/rhf/CustomTextField';
import {
  getSMEIRPConfig,
  getSMInterferenceFields,
  getSMPowerConfig,
  smUserPowerFields,
} from '../configs/config-utils';

export const capacity: PMPFormField = {
  label: 'Capacity',
  attrName: 'capacity',
  getter: 'radios.0.equipment.capacity',
};
export const pmp450EquipmentFields = [
  {
    attrName: 'ap_radio_number',
    getter: 'radios.0.antennas.0.sector',
    refreshesChoices: true,
    afterOnChange(newValue, formMethods, newChoices) {
      const { setValue } = formMethods;
      setValue('radios.0.antennas.0.sector', newValue);
    },
  },
  band,
  product,
  capacity,
  regulation,
  {
    attrName: 'aes_encryption',
    getter: 'radios.0.equipment.aes_encryption',
  },
];

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'sm_equipment',
  component: NDEquipmentPanel,
  fields: pmp450EquipmentFields,
};

export const productPanel: PMPFormPanel = {
  kind: 'product',
  title: 'Product Configuration',
  name: 'sm_product',
  component: NDEquipmentPanel,
  fields: [
    {
      attrName: 'bandwidth',
      getter: 'radios.0.equipment.bandwidth',
      show({ formGetter }) {
        return !isPMP450v(formGetter('radios.0.equipment.product'));
      },
    },
    {
      attrName: 'color_code',
      getter: 'radios.0.equipment.color_code',
    },
    {
      attrName: 'data_channel',
      getter: 'radios.0.equipment.data_channel',
    },
    {
      ...smRangeBaseConfig,
      editable: false,
    },
    {
      attrName: 'frame_period',
      getter: 'radios.0.equipment.frame_period',
    },
    {
      attrName: 'downlink_data',
      getter: 'radios.0.equipment.downlink_data',
    },
    {
      attrName: 'control_slots',
      getter: 'radios.0.equipment.control_slots',
    },
    {
      attrName: 'effective_control_slots',
      getter: 'radios.0.equipment.effective_control_slots',
    },
    {
      attrName: 'dual_payload',
      getter: 'radios.0.equipment.dual_payload',
      refreshesChoices: true,
    },
    {
      attrName: 'dl_max_multiplier',
      getter: 'radios.0.equipment.dl_max_multiplier',
      refreshesChoices: true,
    },
    {
      attrName: 'ul_max_multiplier',
      getter: 'radios.0.equipment.ul_max_multiplier',
      refreshesChoices: true,
    },
  ],
};

export const ndAntennaGain: PMPFormField = {
  label({ formGetter, ap }) {
    const componentCarriers = formGetter('radio_qty');
    const carrierQty = get(ap, 'radios.0.equipment.carrier_qty');
    return carrierQty > 1 && componentCarriers > 1
      ? 'ND Antenna Gain - Carrier 1'
      : 'ND Antenna Gain';
  },
  reCalculate: true,
  attrName: 'ap_antenna_gain',
  getter: 'radios.0.antennas.0.ap_antenna_gain',
  warning({ formGetter }) {
    return formGetter(`radios.0.warnings.gain_warning`);
  },
  component: GenericScaledField,
  tooltip({ formGetter }) {
    return formGetter('radios.0.antennas.0.config.ap_antenna_gain_tooltip');
  },
  editable: false,
  precision: 1,
  units: 'dBi',
};

export const antennaPanelFields: PMPFormField[] = [
  {
    ...antennaSelection,
    warning({ sm }) {
      return get(sm, 'radios.0.warnings.antenna');
    },
  },
  {
    ...antennaHeight,
    componentProps({ sm, formGetter }) {
      return antennaHeightComponentProps(sm, formGetter);
    },
  },
  {
    label: 'Positioner',
    attrName: `use_positioner`,
    getter: `radios.0.antennas.0.config.use_positioner`,
    component: Form.Checkbox,
    show({ choices }) {
      return choices.cabling.internal && choices.cabling.supports_positioner;
    },
    componentProps: ({ formGetter }: PMPShowProps) => {
      return {
        checked: formGetter(`radios.0.antennas.0.config.use_positioner`),
      };
    },
  },
  calculateLoss,
  cableChoices,
  cableLength,
  cableLoss,
  {
    ...azimuth,
    editable: false,
    precision: 1,
    componentProps({ formGetter }) {
      return {
        unitExtraTooltip: `${round(
          formGetter('radios.0.antennas.0.bearing_from_boresight'),
          1
        )}\u00b0 from boresight`,
      };
    },
  },
  {
    ...tilt,
    editable: false,
    component: CustomTextField,
    reCalculate: true,
    componentProps() {
      return {
        formatter: ({ value }) => {
          let tiltType = 'uptilt';
          if (value < 0) {
            tiltType = 'downtilt';
          }
          return `${round(value, 1)}\u00b0 (${tiltType})`;
        },
      };
    },
    warning({ formGetter }) {
      return formGetter(`radios.0.warnings.tilt_has_warning`);
    },
  },
  ndAntennaGain,
];

export const powerPanelFields: PMPFormField[] = [
  gasGroup,
  getSMEIRPConfig(),
  getSMPowerConfig(),
  smUserPowerFields,
  {
    ...getSMInterferenceFields(),
    show({ ap }) {
      return !isPMP450v(get(ap, 'radios.0.equipment.product'));
    },
  },
];

export const antenna: PMPFormPanel = {
  kind: 'antenna',
  title: 'Antenna Configuration',
  name: 'sm_antenna',
  component: NDAntennaPanel,
  fields: antennaPanelFields,
};

export const power: PMPFormPanel = {
  kind: 'power',
  title: 'Power',
  name: 'sm_power',
  component: NDPowerPanel,
  fields: powerPanelFields,
};

export const pmp450ViewConfig = {
  syncFormState,
  equipment,
  product: productPanel,
  antenna,
  power,
};
