import { pmp450ViewConfig } from './configs/PMP450ViewConfig';
import productFamily from '../pmp_families.json';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import ePMPViewConfig from './configs/ePMPViewConfig';
import { cnReachViewConfig } from './configs/cnReachViewConfig';
import { cnWave28ViewConfig } from './configs/cnWave28ViewConfig';
import { cnWave60ViewConfig } from './configs/cnWave60ViewConfig';
import { PMPChoices } from 'src/pages/ptp/utils';
import { pmp450vViewConfig } from './configs/PMP450vViewConfig';
import { isPMP450v } from '../config-utils';
import PTP670HCMPViewConfig from './configs/PTP670HCMPViewConfig';
import PTP700HCMPViewConfig from './configs/PTP700HCMPViewConfig';

const productViewConfigs = {
  'PMP-450': pmp450ViewConfig,
  'PTP-670-HCMP': PTP670HCMPViewConfig,
  'PTP-700-HCMP': PTP700HCMPViewConfig,
  ePMP: ePMPViewConfig,
  cnReach: cnReachViewConfig,
  'cnWave 28': cnWave28ViewConfig,
  cnWave: cnWave60ViewConfig,
};

export function getProductPanels(product: string, isND = true) {
  if (!isND && isPMP450v(product)) {
    return pmp450vViewConfig;
  }
  const family = productFamily[product];
  const panels = productViewConfigs[family];
  return panels;
}

export function syncFormState(
  getter: UseFormGetValues<any>,
  setter: UseFormSetValue<any>,
  panels: any,
  newChoices: PMPChoices,
  attr: string,
  ap: any
) {
  // since performance summary panel fields are not rendered using the
  // reusable panels we have write setters here
  setter(
    `results.summary.local.mod_mode_required.value`,
    newChoices['summary']['mod_mode_required_ap']['value']
  );
  setter(
    `results.summary.remote.mod_mode_required.value`,
    newChoices['summary']['mod_mode_required_sm']['value']
  );
  for (const name of [
    'equipment',
    'product',
    'sector',
    'multiRadioEquipment',
  ]) {
    const panel = panels[name];

    if (panel) {
      let show = true;
      if (panel.show) show = panel.show({ formGetter: getter, ap });
      if (show) {
        for (const field of panel.fields) {
          // choices response will return the correct next
          // value to take for each equipment field, so update
          // when they dont match
          const currentValue = getter(field.getter as any);
          let choiceValue = newChoices.equipment[field.attrName]?.value;

          if (field.nextValue != null) {
            choiceValue = field.nextValue(
              currentValue,
              newChoices,
              getter,
              attr
            );
          }
          if (currentValue !== choiceValue) {
            setter(field.getter as any, choiceValue, { shouldDirty: true });
          }
        }
      }
    }
  }
  for (const name of ['antenna', 'power']) {
    const panel = panels[name];
    let show = true;
    if (panel.show) show = panel.show({ formGetter: getter, ap });
    if (show) {
      for (const field of panel.fields) {
        if (field.nextValue != null) {
          // when present, nextValue() will determine the correct
          // next value for a field when the api response doesn't
          // know how to calculate it, e.g. when antenna needs to change
          const getterKey =
            field.valueGetter != null ? field.valueGetter : field.getter;
          const value = field.nextValue(
            getter(getterKey),
            newChoices,
            getter,
            attr
          );
          if (field.nextValueNullOk === true || value != null) {
            // set the internal form value to the next value
            setter(getterKey, value, {
              shouldDirty: true,
            });
          }
        }
      }
    }
  }
  if (panels.sectorPower) {
    let show = true;
    if (panels.sectorPower.show)
      show = panels.sectorPower.show({ formGetter: getter, ap });
    if (show) {
      for (const field of panels.sectorPower.fields) {
        if (field.nextValue != null) {
          // when present, nextValue() will determine the correct
          // next value for a field when the api response doesn't
          // know how to calculate it, e.g. when antenna needs to change
          const getterKey =
            field.valueGetter != null ? field.valueGetter : field.getter;
          let value = field.nextValue(
            getter(getterKey),
            newChoices,
            getter,
            attr
          );
          if (field.nextValueNullOk === true || value != null) {
            // set the internal form value to the next value
            setter(getterKey, value, {
              shouldDirty: true,
            });
          }
        }
      }
    }
  }
}

export const getSMCacheKeys = (projectId, id) => {
  return {
    key: [projectId, 'subscriber', id],
    choicesKey: [projectId, 'subscriber', id, 'choices'],
    profileKey: [projectId, 'profile', id],
  };
};
